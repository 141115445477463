@import "../../../assets/sass/colors";

.listproducts {
     .promo{
           text-decoration: line-through;
           color:red;
     }
}

@media only screen and (max-width: 1024px) {
 
}
