.tile-video {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
}




.self-view {
  height: 200px;
  width: auto;

  position: absolute;
  right:  0;
  bottom: 0;
  left:   auto;
  top:    auto;
}

@media only screen and (max-width: 600px), only screen and (max-height: 700px)  {
  .self-view {
    height: 100px;
  }
}

.self-view video {
  position: relative;
}
.dayli .self-view video {
  object-fit: cover;
  right: 6px;
  bottom: 6px;
}

/* Put screen share front and center in our grid when there is one active */
.tile-screenshare {
  grid-column: 1 / -1;
  grid-row: 1;
}


.audio-toggler {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
}
.audio-toggler.pause {
  transform: scale(2);
}