@import "../../assets/sass/colors";
.section387 {
    margin-bottom: 30px;
    overflow: hidden;
    background: #f5f5f5;
    padding-top: 50px;
    padding-bottom: 50px;
    img{
      width: 70%;
    }
    .cardplatform{
    text-align:center
    }
}
.quisommesnous {
 
    display: flex;
    align-items: flex-start !important;
    background: #FFF;
    min-height: 100vh;


    .titleunique{
        background-color: #fb6c13;
        color: #ffffff;
        font-weight: bold;
        height: 32px;
        padding: 5px;
    }



    a{
        color: $text-color;
        text-decoration: none;
    }
  
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
