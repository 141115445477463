@import "../../../assets/sass/colors";

.suivilivraison {
  .slick-arrow:hover, .slick-arrow:focus {background: #000 !important}
   .slick-arrow{
          background: #000;
    z-index: 99;
    display: block;
    width: 35px;
    height: 32px;
    padding-top: 2px;
  
    color: #000;
    border-radius: 4px;
    }
   .slick-next {
       right: -37px;
   }
   .slick-prev{
     left: -37px;
   }
   
}

@media only screen and (max-width: 1024px) {
  
}
