@import "../../../assets/sass/colors";

.detailcmd {
   
   .tabsitems{
      .rowitmtop{
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
      table{
          border: 1px solid #000;
              border-left: 1px solid #000;
       tr{
       td.left{
            text-align:left;
       }

         td{
                 box-shadow: none;
                background: #FFF;
                border-left: 0px;
                border-right: 0px;
                padding-bottom: 10px;
    padding-top: 10px;
    font-size: 12px;

                h5{
                color:#004abf
                }
                 h5.red{
                  color:#ff1616
                }
         }
       }
      }
   }
    .detcommande{
      display:block !important;
    margin-top:30px;
    margin-bottom:30px;
    .items{
    a{
     line-height: 23px;
    }
      .rowitmtop{
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
    }
        .process-item{
            padding-left: 5px;
    padding-right: 5px;
            padding-top: 10px;
    padding-bottom: 10px;
          box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
        -webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
        -moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
            border-radius: 10px;
            background: #FFF;
            margin-bottom:20px;
            strong{
                  margin-bottom: 11px;
                  display: block;
            }
            .rowsitm{
                margin-bottom: 10px;
              display: flex;
    justify-content: space-around;
    align-items: center;
    div{
         width: 50%;
         span{ 
               float: left;
    padding-left: 8px;
          }
    }
            }
       }
    }
.flx990{
   display: flex;
    justify-content: space-between;
    align-items: center;
}
 .btnrmv{
  padding: 10px;
    border-radius: 10px;
    color: #FFF;
    font-weight: bold;
    float: right;
    line-height: 8px;
    margin-bottom: 11px;
    width:48%;
 }

 .suivicmd{
   .item{
   .ico{
     font-size: 43px;
   }
     label{
      font-weight:bold;

       img{
             width: 120px;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
       }
     }
   }
 }
}

@media only screen and (max-width: 1024px) {
    .detailcmd {
         .froweb98{
          display:none;
       } 
    }
}
