@import "../../assets/sass/colors";
  ._1xuQrbasquet{
        height: 20px;
    width: 20px !important;
    border-radius: 50%;
    display: block;
    position: relative;
    cursor: pointer;
    border: 1px solid #c9c9c9;
    }
.basket-item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    overflow: hidden;
    transition:500ms ;
    display: flex;
    border: 2px solid white;
    min-height: 100px;
    .image{
        width: 30%;
        background-color: $grey2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    border-radius: 10px;

    }
    .delete-btn{
     padding: 2px;   
    }


    
    .info{
        width: 70%;
        padding: 8px 15px;
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        h5{
            display: flex;
            align-items:center;
            justify-content: space-between;
            font-size: 15px;
            margin-bottom: 15px;
            span{
                background-color: $primary;
                border-radius: 10px;
                font-size: 15px;
                color: white;
                padding: 3px 10px ;
                margin-left: auto;
                font-family: 'Montserrat-Medium',sans-serif;
            }
        }
        .address{
            margin-bottom: 10px;
        }
        .btn{
            padding: 4px 20px;

        }
    }
    .btn{
        align-self: flex-start;
    }

}

@media only screen and (max-width: 1024px) {
    .basket-item {
     
    }
}
