@import "../../assets/sass/colors";

.articles, .tests{
    .ps-block__right{
        min-height: 66px !important
    }
}
.tests {
 .noshadowbox{
  border: 1px solid #CCC !important;
  box-shadow:none !important;
  margin-bottom:20px
 }
}

.bul{

    background: #00e3de;
    background-image: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #cbfcff 100%);
    border-radius: 20px;
    padding: 43px 30px;
    text-align: center;
    border: 1px solid #a5a79a;
	h2{
	      color: #FFF;
          font-size: 19px !important;
          margin-bottom:20px;

	}
	  a{
	display: block;
    background: #fff5ce;
    background-image: linear-gradient(90deg, #f4f4f4 0%, #E2DFDF 35%, #d2d2d2 100%);
    padding: 18px;
    border-radius: 40px;
    color: #000;
    font-size: 15px;
    text-align: center;
    text-transform: uppercase;
    font-weight: bold;
    border: 1px solid #a5a79a;
	    
	  }
}

.bul2{
    background: #fff5ce;
    background-image: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #cbfcff 100%);
    border-radius: 40px;
    padding: 16px 27px;
    text-align: center;
 
    margin-top:40px;
     margin-bottom:40px;
    div{
     width: 100%;
    text-align: center;
    top: 9px;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    position:absolute;
    h2{
	color: #000;
    font-size: 19px !important;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #a5a79a;
    text-transform:uppercase;
    background: #f4f4f4;
     background-image: linear-gradient(90deg, #f4f4f4 0%, #E2DFDF 35%, #d2d2d2 100%);
    border-radius: 10px;
    z-index: 99;
    width: 260px;

	}
    }
	
	  a{
    display: block;
    
    padding: 7px 9px;
    border-radius: 20px;
    color: #FFF;
    font-size: 17px;
    margin-top: 20px;
    text-align: center;
 
    font-weight: bold;

	    
	  }
}


.buleright{
    background: #00e3de;
     background-image: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #cbfcff 100%);
    border-radius: 20px;
    padding: 16px 27px;
    text-align: center;
    border: 1px solid #a5a79a;
    margin-top:40px;
     margin-bottom:40px;
    div{
    width: 100%;
    text-align: center;
    top: 9px;
    right: 48px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: absolute;
    h2{
	color: #000;
    font-size: 19px !important;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #a5a79a;
    text-transform:uppercase;
    background: #f4f4f4;
     background-image: linear-gradient(90deg, #f4f4f4 0%, #E2DFDF 35%, #d2d2d2 100%);
    border-radius: 10px;
    z-index: 99;
    width: 260px;

	}
    }
	
	 p{
 
	 font-size: 14px;
    color: #000;
    margin-top: 25px;
    text-align: right;
	  }
}

.buleft{
    background: #43c3cc;
     background-image: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #cbfcff 100%);
    border-radius: 20px;
    padding: 16px 27px;
    text-align: left;
    border: 1px solid #a5a79a;
    margin-top:40px;
     margin-bottom:40px;
    div{
    width: 100%;
    text-align: center;
    top: 9px;
    left: 28px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: absolute;
    strong{display:block}
    h2{
	    color: #000;
    font-size: 17px !important;
    margin-bottom: 20px;
    padding: 15px;
    border: 1px solid #a5a79a;
    background: #f4f4f4;
     background-image: linear-gradient(90deg, #f4f4f4 0%, #E2DFDF 35%, #d2d2d2 100%);
    letter-spacing: -0.3px;
    border-radius: 10px;
    z-index: 99;
    width: max-content;

	}
    }
    ul{
        margin-top: 10px;
     li{
          display: revert;
    align-items: center;
    list-style-type: initial;
    list-style: initial;
     }
    }
	
	 p{
 
	 font-size: 14px;
    color: #000;
    margin-top: 25px;
    text-align: left;
	  }
}

@media only screen and (max-width: 1024px) {
    .tests {

    }

}