@import "../../assets/sass/colors";
.numeros .shop__header--select::before{display:none !important}
.numeros .MuiInputLabel-root{    font-size: 17px;
    top: -5px;}
.numeros {

.ps-block--site-features .ps-block__right {
    min-height: 90px !important;
}

.para3{
	    text-align: left;
    border-left: 4px solid #1ebeb6;
    padding-left: 12px;
   
    min-height: 80px;
    overflow: hidden;
}
 .view--select{
    border: 1px solid #e7e7e7;
    padding: 0.6rem 3rem 0.6rem 1.2rem;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 0.5rem;
    background: #FFF;
    border-radius: 5px;
    height: 40px;
        width: 100%;
 }
    .shop__header--select{
     position:relative
    }
   .shop__header--select::before {
    right: 14px;
    }

    .shop__header--select::before {
    border-bottom: 2px solid #5d5d5d;
    border-right: 2px solid #5d5d5d;
    content: "";
    display: block;
    height: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 50%;
    width: 7px;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: .7;
}

}

@media only screen and (max-width: 1024px) {
    .numeros {

    }

}