@import "../../assets/sass/colors";

.profiltherapeut {
.hourpos{
       background: #03becb;
    color: #FFF;
    border-radius: 10px;
    position: absolute;
    padding: 4px 6px;
    right: 9px;
    top: 7px;
}

   .thisprofil{

          background: #46c3cc;
          padding:20px 0px !important;
          padding-bottom:0px !important;
              margin-bottom: 0px;
          min-height: 120px;
          .therapeutescontent{
                background: none !important;
                 padding-bottom:0px !important;
              margin-bottom: 0px;
                border: 0px !important;
                 box-shadow: none !important;
                 .itemimg{
                    -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
                     box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);

                 }
                 h3{
                  color: #FFF;
                 }

                 .listtags .item {
                    border: 1px solid #ffffff;
                    color: #FFF;
                    }
          }
   }
   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    color:#FFF;
        font-family: "Work Sans", sans-serif;
    font-weight: bold;
        font-size: 12px;
    text-transform: capitalize;
   }

   .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{

     color:#343434;
       background: #FFF;
    border-top-left-radius: 10px;
     border-top-right-radius: 10px;
   }
   .css-1aquho2-MuiTabs-indicator{display:none !important}
   .css-1ujnqem-MuiTabs-root{
        margin-top: 30px;
   }
   .css-1gsv261{
    border-bottom:0px
   }
   .tabpnel{
    padding:0px;
    .MuiBox-root{
     padding:0px;
    }
   }



  .bgplaning{
     box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 10px;
    background: #FFF;
   }

   .slick-next, .slick-prev {
    background-position: 50%!important;
    background-repeat: no-repeat!important;
    background-size: 50px!important;
    content: ""!important;
    height: 20px;
    top: 21px;
    width: 20px;
}
  .slick-prev {
    
   background:url('../../../public/images/boutonretour.png')
}
  .slick-next {
    
   background:url('../../../public/images/boutonavance.png')
}
  .slick-prev:hover, .slick-prev:focus {
    
   background:url('../../../public/images/boutonretour.png')
}
  .slick-next:hover, .slick-next:focus {
    
   background:url('../../../public/images/boutonavance.png')
}
.slick-prev:before , .slick-next:before {
    display:none
}

   .calendar{
   padding: 20px;
     .colcalendar{
padding-left: 10px;
padding-right: 10px;
        h5{
              color: #6e6e6e;
    font-size: 15px;
    text-align: center;
    margin-bottom:2px;
        }
        .dd{
           color: #000000;
    font-size: 12px;
    display: block;
    text-align: center;
        }
        .actions{
        margin-top: 15px;
        display:block;
          div{
                     padding: 11px;
    border: none;
    border-radius: 30px;
    text-align: center;
    color: #cacdd0;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: default;
          }
    div.active{
        border:1px solid #4ec5ca;
        padding: 10px;
    
    border-radius: 30px;
    text-align: center;
    color: #00818a;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
          }
           div.active:hover{
              text-decoration: none;
    color:#FFF;
    transition: all 300ms ease;
        background-image: linear-gradient(to right,#1ebeb6 51%,#109ba5 100%);
        color:#FFF;
           }

    div.bloque{
        border:1px solid #dc3545;
        padding: 10px;
    
    border-radius: 30px;
    text-align: center;
    color: #dc3545;
    font-weight: bold;
    margin-bottom: 15px;
    cursor: pointer;
          }
           div.bloque:hover{
              text-decoration: none;
    color:#FFF;
    transition: all 300ms ease;
        background-image: linear-gradient(to right,#dc3545 51%,#dc3545 100%);
        color:#FFF;
           }



        }
     }
   }
 
}

.reviews-comments-item-text {
    padding: 20px 20px 15px;
    background: #F5F6FA;
    border-radius: 6px;
        width: 100%;
}
.review-comments-avatar{
  position: relative;
    margin-right: 20px;
}
.review-comments-avatar:before {
    content: '';
    position: absolute;
    right: -20px;
    top: 50%;
    border: solid transparent;
    height: 0;
    width: 0;
    border-color: #fff;
    border-right-color: #F5F6FA;
    border-width: 10px;
    margin-top: -10px;
    pointer-events: none;
}

 

@media only screen and (max-width: 1024px) {
    .profiltherapeut{

    }

}