@import "../../assets/sass/colors";


.full-screen-image {
  background-color: rgba(0, 0, 0, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 1000;
}
.full-screen-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}


.detailproduct {
    min-height: 90vh;
    padding: 60px 0;
    padding-top:0;
    padding-bottom:0;

    .Xg78B li label{cursor:pointer}
    .coloractive{
            border-bottom: 2px solid #444040 !important;
    border-radius: 50%;
    padding: 1px;
}
.prom99{
   color: #353434 !important;
    font-weight: bold !important;
    font-size: 2.3rem !important;
}

.sharebyttons{
    -webkit-box-shadow: 1px 4px 12px 1px rgba(0,0,0,0.5);
box-shadow: 1px 4px 12px 1px rgba(0,0,0,0.5);
position: absolute;
    background: #FFF;
    padding: 7px;
    border-radius: 20px;
    right: -8px;
    top: 78px;
}

.slikslideproduct{
   .slick-arrow{
        z-index: 99;
    background: #ddd;
    display: block;
    width: 35px;
    height: 32px;
    padding-top: 2px;
    color: #000;
    border-radius: 4px;


   }
   .slick-prev{
     left:10px
   }

   .slick-next{
     right:10px
   }

}

.tailactive{
         border-bottom: 2px solid #444040 !important;

    padding: 1px;
}

    .ps-product__sharing{display:block;}

    .actioncart{

    display: flex;
    align-items: center;
    justify-content: space-around;


      a{

      }
    }
  .showallcomments{
        text-align: center;
    display: block;
    color: #000;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 7px;
    margin-bottom: 4px;
  }
      .listcomments{
   display: flex;
    justify-content: flex-start;
    align-items: center;
    div{

    }
    .itemimg{
      width:10%;
      margin-right: 10px;
      img{
              width: 70px;

      }
    }
    .itemmessage{
      font-size: 12px;
          width: 80%;
    }
    .itemaction{
    display:flex;
       a{
             margin-right: 5px;
              margin-left: 5px;
        span{
                color: #000;
    font-size: 12px;
        }
        img{
             width: 25px;
        }
       }
    }
  }

    .form-group--number{max-width:100% !important;margin-bottom:20px}
    .ps-container{padding-left:15px}
    .ps-product--detail{margin-top:50px}
    .ps-product__meta_web{display:block}
     .ps-product__meta_mobile{display:none}
     .ps-tab-root-web{display:block}
     .ps-tab-root-mobile{display:none}

    .attrs{
        border: 2px solid $grey5;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
    }


    .actionshare{
 position: absolute;
    right: 13px;
    top: 34px;
    z-index:999;
    a{
     display:block;
     margin-bottom:10px

    }
    img{
          width: 32px;
    }
    i{
      font-size: 26px;
    color: #7b7b7b;
    }
}

    .video {
       position:relative;
       .center{
           position: absolute;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
         img{
              width: 82px;
         }
       }
    }

 .backsliderimg{
    width: 100%;
    min-height: 350px;
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .product_detail_img {
    max-width: 80%;
    margin: auto;
  }


    .btnflexblack{
     display: flex !important;
    justify-content: space-around;
    align-items: center;
    margin-left: 0px !important;
    span{
          min-width: 245px;
    text-align: left;
    }
}
    .ps-btn--black img{
      width: 30px;
    }
    .dettitleprmobile{display:none;}
    .ps-product__meta{position:relative}
    .ps-product__boutique{

    .ps-btn{
          background: #000;
    font-size: 13px;
    padding: 6px 20px;
    margin-left: 10px;
    color:#FFF;
    }
    }

    .ps-product--detail .ps-product__gallery .slick-arrow:first-child{
    left:4px
    }
    .ps-product--detail .ps-product__gallery .slick-arrow:last-child {
    right: 35px;
}

ul.Xg78B{
    display: inline-block;
    list-style: none;
    padding: 0;
    li{
          margin-right: 8px;
          display: inline-block;
          margin-top: 8px;
    }
}

.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
    font-size:16px !important
}
.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected{
    color:#000 !important
}
.css-1aquho2-MuiTabs-indicator{
    background-color: #000;
}
.ps-product__content{
     border-bottom: 1px solid #d8d8d8;
}
.slidemobile{display:none}
}


@media only screen and (max-width: 490px) {
     .ps-product--detail .ps-product__meta{
      display:block
    }
}
@media only screen and (max-width: 1024px) {

   .actioncart{
     display:block !important;
     a{
      width:100%;
     }
   }
 .form-group--number{width:100% !important;margin-bottom:0px !important}
.addcomment{
    position: absolute;
    top: 13px;
    right: 21px;
    button{
         background: none;
    border: 0px;
    font-size: 22px;
    }
    a{
     i{
             font-size: 20px;
    color: #000;
     }
    }
}
.ps-product__sharing{display:none}
.actionshare{
 position: absolute;
    right: 13px;
    top: 34px;
    a{
     display:block;
     margin-bottom:10px

    }
    img{
          width: 32px;
    }
    i{
      font-size: 26px;
    color: #7b7b7b;
    }
}
.ps-tab-root-mobile{
  .showallcomments{
        text-align: center;
    display: block;
    color: #000;
    font-size: 12px;
    text-decoration: underline;
    margin-top: 7px;
    margin-bottom: 4px;
  }
  .listcomments{
   display: flex;
    justify-content: space-between;
    align-items: center;
    div{

    }
    .itemimg{
    width: auto;
      img{
              width: 40px;
      }
    }
    .itemmessage{
      font-size: 12px;
      width: auto;
      min-width: 210px;
    }
    .itemaction{
    display:flex;
       a{
             margin-right: 5px;
              margin-left: 5px;
        span{
                color: #000;
    font-size: 12px;
        }
        img{
             width: 25px;
        }
       }
    }
  }

  .item{
        border-bottom: 1px solid #e1e1e1;
    padding-bottom: 10px;
    margin-bottom: 24px;

    h6{
      font-size: 15px;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 13px;
    }
      p{
        text-align: center;
      }

  }
}
.ps-tab-root-web{display:none !important}
.ps-tab-root-mobile{display:block !important}
.forwatsap{ visibility:visible !important;}
.ps-product__meta_web{display:none !important}
     .ps-product__meta_mobile{display:block !important}
   .noborder{
     border-bottom:none !important;

   }
   .ps-product--detail .ps-product__meta{    justify-content: flex-end;}
   .ps-product__boutique {

    left: 0px;
    right:none;
  }
    .dettitleprweb{display:none}
     .dettitleprmobile{display:block !important;margin-top: 0px;
    font-size: 17px !important;
    font-family: 'Montserrat-Medium',sans-serif;
    border-bottom: 1px solid #e1e1e1;
    padding-bottom: 8px;}
    .detailproduct {
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected,.css-1h9z7r5-MuiButtonBase-root-MuiTab-root{font-size: 13px !important;}
    .ps-product__shopping{
      .ps-product__actions{
        display:none;
      }
    }
      .ps-product__rating{
       justify-content: center;
        margin-bottom: 10px;
      }
       .ps-product--detail .ps-product__thumbnail figure{
         margin-left:5px
       }
       .ps-product__boutique{position:relative}
       .ps-product--detail .ps-product__header .ps-product__thumbnail{display:none}
        .ps-product--detail{
         padding: 7px !important;
         margin-top: 10px !important;
       }
       .ps-product--detail .ps-product__shopping figure {
                width: 100%;
                margin-left: 5px;
            }
        .ps-product--detail .ps-product__shopping .ps-btn {
    display: block;
    width: 100%;
    margin-left: 7px;
    margin-top: 20px;
}
    }

   .prom99{
   color: #353434;

    font-size: 18px !important;
}

    .ps-product__price{font-size: 17px !important;
    font-weight: 600;
    text-align: center;margin-bottom:6px !important}
    .slidemobile{
      display:block !important;text-align:center;    margin-bottom: -25px;padding: 0px !important;
      .backsliderimg{
        width: 100%;
        min-height: 500px;
        background-position: bottom;
        background-repeat: no-repeat;
       }


      .video {
         position:relative;
         .center{
             position: absolute;
      width: 100%;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
           img{
                width: 82px;
           }
         }
      }

    }
}
