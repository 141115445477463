@import "../../../assets/sass/colors";
.listing {
.itemactb{    border-bottom: 3px solid #000;
    height: 83px !important
;}
.itemactbl{    border-bottom: 3px solid #000;
}
.promomrg{margin-bottom:20px;margin-top:20px}
 .bigcategoriesfltr{
   justify-content: center;
   .item{
     margin-right:20px;
     margin-left:20px;
   }
 }
 .bigboutiques{
   .item2{
         height: 80px;
    text-align: center;
    width: 80px;
    margin-right:20px
   }
 }
 .enpromo{
  position:relative;
  display:block !important;
  img{
   width:250px;
  }

 }
}

@media only screen and (max-width: 1024px) {
  .enpromo{
  
  img{
   width:190px !important;
  }
 }
}
