@import "../../assets/sass/colors";
.menudash {
   .card-img-top{
       width: 200px;
       margin: 0 auto;
    }
    .logout{
    text-align:center;
    margin-top:15px;
    margin-bottom:15px
    }
    .logout_btn {
   
    position: relative;
    padding: 10px 30px;
    border: none;
   
    color: #fff;
    border-radius: 4px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: #495057;
}
}

@media only screen and (max-width: 1024px) {
    .menudash {
    }
}
