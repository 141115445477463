@import "../../assets/sass/colors";
.apropos {
    min-height: 100vh;
    display: flex;
    align-items: flex-start !important;
    background:#FFF;
    .epsum{
      background: #FFF;
      margin-top: 30px;

      .heading{
           
    background: #d5d5d5;
    background-image: linear-gradient(90deg, #d5d5d5 0%, #eee 35%, #eee 100%);
    margin-bottom: 6px;
    padding: 14px 1.786em;
    border-radius: 50px;
    padding-bottom: 9px;
    line-height: 29px;
      }
      .body{
           border-bottom: 6px solid #eee;
      }
    }


    .titleunique{
        background-color: #fb6c13;
        color: #ffffff;
        font-weight: bold;
        height: 32px;
        padding: 5px;
    }



    a{
        color: $text-color;
        text-decoration: none;
    }
  
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
