@import "../../../assets/sass/colors";

.addproduct {
      .p99{
            appearance: inherit;
    -webkit-appearance: auto !important;
      }

      .form-control{
       margin-bottom:20px;
      }

      ._35LeVin ._1xuQr{
          height: 20px;
    width: 20px;
    border-radius: 50%;
    display: block;
    position: relative;
    cursor: pointer;
    border: 1px solid #c9c9c9;
      }


      .btnupload{
           background: #495057;
    color: #FFF;
    width: 100%;
    display: block;
    border-radius: 6px;
    margin-bottom:20px;
      }

      .inp {
    background: #eee;
    height: 39px;
    border-radius: 10px;
    font-size: 13px;
    margin-bottom: 20px;
    line-height: 35px;
    -webkit-appearance: auto;
    border: 1px solid #ced4da;

}
  .photosblock {
    display: flex;
    align-items: center;
    justify-content: space-between;
     .item{
          width: 33.33%;
              margin-right: 25px;
     
     }
}
.psr {
    font-size: 12px;
}
}

@media only screen and (max-width: 1024px) {
    .addproduct {
     
    }
}
