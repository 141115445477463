@import "../../../assets/sass/colors";
.forMMp{
       display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}
.vosventes {


.fc-v-event .fc-event-title {
    top: 14px;
 
    position: absolute;
  
}

     .formsoldpauy {

     .actiftype{
           border-bottom: 3px solid;
     }
     
       .e9039{

       margin-bottom:30px;
       }
        .typepaiement{

            display: flex;
    justify-content: center;
    align-items: center;



          a{
              margin-left: 5%;
    margin-right: 5%;
    width: 50%;
    margin-bottom: 10px;
          }
           img{
             width: 225px;
           }
        }
     }
}

.footermodflex{     width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;}

@media only screen and (max-width: 1024px) {
 
 
}
