@import "../../assets/sass/colors";
.messages {
   .notfound{
    text-align: center;
    margin-top: 20%;
}
.tag{
         border-radius: 20px;
    width: 77px;
    font-size: 12px;
    padding: 0px;
    height: 19px;
    line-height: 19px;
}
.scroolmessage{
    height: 500px;
    overflow-y: scroll;
}
 .dashboard-header {
    position: relative;
    z-index: 5;
    padding: 9px 27px;
    border-radius: 10px 10px 0 0;
    text-align: left;
    color: #fff;
    padding-bottom: 2px !important;
    background: #46c3cc;

    h3{
      font-size:20px;
      color:#FFF;
    }

   }
    .chat-box{
  border-left: 1px solid #eee;
}
.chat-wrapper .chat-widget_input{width: 99%;}
.message-counter{text-align:center}
.chat-contacts-item .chat-contacts-item-text span {
     
    right: -11px;
    top: 7px;
   
}

.dashboard-message .booking-list-contr {
    top:20px;
}
.dashboard-message-text img {
    float:left;
    width:90px;
    height:auto;
    border-radius:6px;
    box-shadow: 0 9px 16px rgba(58, 87, 135, 0.2);
    margin-right:20px;
}
.dashboard-message-text h4 {
    text-align:left;
    padding:8px 0 6px 0;
}
.dashboard-message-text h4 a {
    color: #566985;
}
.chat-box {
    padding:50px 30px 60px;
    min-height:300px;
}
.chat-message {
    padding-left:70px;
    margin-bottom:30px;
}
.chat-message:last-child {
    margin-bottom:0;
}
 
.message-input {
    float:left;
    width:100%;
    position:relative;
    border-right:1px solid #eee;
}
.message-input textarea{
    position:relative;
    width:100%;
    border:none;
    background:#fff;
    z-index:1;
    resize: vertical;
    min-height:60px;
    padding:25px 140px 0 30px;
}
.message-input button {
    position:absolute;
    right:20px;
    border-radius:36px;
    width:100px;
    height:36px;
    line-height:36px;
    background:none;
    border:none;
    font-size:14px;
    top:12px;
    z-index:2;
    color:#fff;
    cursor:pointer;
}
.chat-contacts-item {
    float:left;
    width:100%;
    position:relative;
    padding:20px  20px 20px 70px;
    border-bottom:1px solid #eee;
    box-sizing:border-box;
    background:#fff;
}
.chat-contacts-item:last-child {
    border-bottom:none;
}
.chat-contacts-item .dashboard-message-avatar{
    position:absolute;
    top:15px;
    left:29px;
}
.chat-contacts-item .chat-contacts-item-text p {
    height: 26px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    font-size: 12px;
    color: #888;
}
.chat-contacts-item .chat-contacts-item-text h4 {
    text-align:left;
    font-size:12px;
    color:#666;
    padding-bottom:4px;
}
.chat-contacts-item .chat-contacts-item-text span {
    position:absolute;
    right:20px;
    top:21px;
    font-size:9px;
    color:#999;
}
.message-counter {
    position:absolute;
    left:0;
    bottom:-2px;
    z-index:10;
    width:20px;
    height:20px;
    line-height:20px;
    font-size:11px;
    color:#fff;
    border-radius:100%;
    background:#46c3cc
}
.nopadding {
   padding: 0 !important;
   margin: 0 !important;
}
.chat-contacts:before {
    content:'';
    position:absolute;
    left:-31px;
    width:1px;
 
    height:100%;
    top:0;
    z-index:10;
}
.chat-contacts-item-text {
    z-index:20;
    position: relative;
}
.chat-contacts-item:after {
    content:'';
    position:absolute;
    right:0;
    width:6px;
    height:0;
    z-index:2;
    top:0;
    transition: all .2s ease-in-out;
}
.chat-contacts-item_active:before {
    content:'';
    position:absolute;
    right:0;
    top:0;
    bottom:0;
    left:-30px;
    background:#ECF6F8;
 
}
.chat-contacts-item.chat-contacts-item_active:after , .chat-contacts-item:hover:after {
    height:100%;
}
.chat-contacts-item {
    float: left;
    width: 100%;
    position: relative;
    padding: 20px 20px 20px 70px;
    border-bottom: 1px solid #eee;
    box-sizing: border-box;
}
.chat-contacts-item .dashboard-message-avatar {
    position: absolute;
    top: 15px;
    left: 0;
}
.dashboard-message-avatar {
    float: left;
    width: 50px;
    height: 50px;
}
.chat-contacts-item .chat-contacts-item-text h4 {
    text-align: left;
    font-size: 12px;
    color: #666;
    padding-bottom: 4px;
}
.chat-contacts-item .chat-contacts-item-text span {
    position: absolute;
    right: 10px;
    top: 4px;
    font-size: 9px;
    color: #999;
}
.dashboard-message-avatar img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
}
.message-counter {
    position: absolute;
    left: 0;
    bottom: -2px;
    z-index: 10;
    width: 20px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    color: #fff;
    border-radius: 100%;
}
.chat-wrapper{
    background:#fff;
    border-radius:0 0 10px 10px;
 
}
.cmun_sm {
    top:50px;
}
.chat-wrapper .chat-widget_input {
    margin-top:30px;
    position:relative;
    width:100%;
    float:left;
    overflow:hidden;
    height: 60px;
}
.chat-wrapper .chat-widget_input textarea {
    padding: 17px 11px 11px;
}
.chat-wrapper  .chat-widget_input button {
    top:11px;
}
.dashboard-tabs .filter-sidebar-header {
    margin-bottom: 0;
    padding: 15px 20px 25px;
}

.chat-message {
padding-left:60px;
margin-bottom:30px;
    overflow: hidden;
    position: relative;
}
.chat-message:last-child {
margin-bottom:0;
}
.chat-message p{
float:left;
padding:10px 20px 15px;
background: #F5F6FA;
border-radius:0 10px 10px 10px;
width:100%;
text-align:left;
font-size:11px;
color: #878C9F;
position:relative;
}
.chat-message.chat-message_user p  {
border-radius: 10px 0 10px 10px;
    color: #000;
    background: #cbedef;
}
.massage-date {
float:left;
font-size:11px;
color:#999;
margin-bottom:11px;
}
.massage-date span{
position:relative;
padding-left:10px;
}
.chat-message.chat-message_user .massage-date {
float:right;
}
.chat-message .dashboard-message-avatar{
position:absolute;
left:0;
top:20px;
}
.chat-message .dashboard-message-avatar , .chat-message .dashboard-message-avatar img {
width:40px;
height:40px;
border-radius:100%;
}
.chat-message.chat-message_user  .dashboard-message-avatar {
left:inherit;
right:0;
}
.chat-message-user-name {
font-size:11px;
color:#999;
position:absolute;
left:4px;
top:70px;
font-weight:600;
}
.chat-message.chat-message_user .chat-message-user-name {
left:inherit;
right:8px;
}
.chat-message.chat-message_user {
padding-left:0;
padding-right:60px;
}
.chat-widget_input textarea { display: block; white-space: pre-wrap; width: 100%; border: none; background: #f9f9f9; font-size: 12px; padding: 17px 11px 13px; overflow: auto; -webkit-overflow-scrolling: touch; max-height: 100px; resize: none; }
.chat-widget_input button { position: absolute; right: 8px; width: 50px; height: 34px; background: #fff; border: 1px solid #eee; border-radius: 6px; font-size: 14px; top: 7px; z-index: 2; color: #fff; cursor: pointer; i{color: #05939b;
    font-size: 20px;}}
.chat-contacts-item_active:before { content: ''; position: absolute; right: 0; top: 0; bottom: 0; left: -30px; background: #ECF6F8; }


.chat-contacts-item_active:after { content: ''; position: absolute; right: 0; width: 6px; height: 100%; background: #cbedef; z-index: 2; top: 0; transition: all .2s ease-in-out; }
}

@media only screen and (max-width: 1024px) {
    
}
