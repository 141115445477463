@import "../../assets/sass/colors";
.login {
    min-height: auto;
    .react-tel-input input {
  width:100%;
    }
    .react-tel-input .country-list .country{
     text-align:left;
    }
    form .form-control{
  border: solid 1px #CACACA !important;
  height:40px;
}
    .forshadolog{
      margin-bottom:20px;
     -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18) !important;
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18) !important;

             border: 1px solid #e6e6e6;
    transition: .3s ease-in-out;
    background-color: #fff;
    border-radius: 10px;
    background: #FFF;
    padding: 20px;
    }
    .js889{
        margin-top: 22px;
    margin-bottom: 0px;
    margin-left: 4px;
    text-align: left;
    }


    .section8891{
    margin-bottom:20px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      a{
              width: 100%;
    text-align: center;
 
       span{
         display: block;
         width:100%;
         text-align:center;
       }
       img{

       }
      }
      a.active{
       background: #f0f0f0;
    border-radius: 5px;
    border: 1px solid #CCC;
      }
    }

    .section8890{
    margin-bottom:30px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      background: #dedede;
    border-radius: 12px;
    padding: 6px;
      a{
           display: block;
           padding: 5px;
           width: 50%;
         
           border-radius: 12px;
           font-weight: bold;
      }
      a:hover{
           color:#000;
      }
      a.active{
         background: #46c3cc;
    color: #FFF !important;
      }
    }
    .logo{
        height: 50px;
    }
    h3 {
        margin-bottom: 63px;
    }
    .fl-wrap {
    float: left;
    width: 100%;
    position: relative;
}
.log-separator:before, .log-separator:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 1px;
    background: #eee;
    z-index: 1;
}
.log-separator span {
    position: relative;
    width: 36px;
    height: 36px;
    line-height: 36px;
    border: 1px solid #eee;
    border-radius: 100%;
    display: inline-block;
    background: #F5F6FA;
    font-size: 10px;
    text-transform: uppercase;
    z-index: 2;
    color: #7d93b2;
}
.log-separator:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 50%;
    height: 1px;
    background: #eee;
    z-index: 1;
    left: inherit;
    right: 0;
}
.ps-btn-dark{
    background:#000;
    color:#FFF
}
    .divform {
      
        background-color: white;
        padding: 30px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
        .btn {
                margin-top: 30px;
    background: #000;
    border: #000;
    border-radius: 4px;
    color: #FFF;
    font-weight: bold;
        }
          .btn:hover {
               
    background: #000;
    border: #fcb800;
 
        }
        .forgetlink {
            color: $grey3;
            margin-left: auto;
            font-size: 12px;
        }
    }
    p {
        color: $grey3;
    }
    a {
        color: $text-color;
        text-decoration: none;
    }
    .rights {
        margin-top: 50px;
        font-size: 12px;
        letter-spacing: 0;
    }
    .btn{
    }
}

.btndwnload{
     width: 100%;
    margin-top: 0px !important;
    margin-bottom: 10px;
    padding: 7px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
}

.loginform2 {
     
    display: flex;
    align-items: center;
    .color2-bg {
       background: #073460 !important;
    padding-right: 0px !important;
    width: 100%;
    border-radius: 6px;
    font-weight: normal;
    font-size: 18px;

}

.displypass{ 
 
 position: relative;
    float: left;
    width: 100%;
    clear: both;

 a{
       position: absolute;
    top: 10px;
    right: 10px;
 }
 }
.main-register-holder .tab-content{
    padding-top:0px;
}
.filter-tags label {
    font-size: 11px !important;
    color: #5b5b5b !important;
    font-weight: normal;
    float: left;
    padding: 0;
    width: 338px;
    padding-left: 10px;
    margin-bottom:20px
}
.actionename{
    ul{
    margin:0px;
    padding:0px;
      li{
         list-style:none;
         float:left;
         width: 50%;
         a{
             display: block;
            color: #5b5b5b;
            border: 1px solid #979a9e;
            border-radius: 5px;
            padding: 3px 10px;
            text-align: center;
         }
         a.active{
          background:#073460;
          color:#FFF !important
         }
         .mrright{margin-right:10px}
         .mrleft{margin-left:10px}
      }
    }
}
.main-register-holder .tabs-menu li.current{
    border-color:#073460
}
.main-register-holder .tabs-menu li a {
    color: #073460;
 
}
.lost_password a{
        color: #000;
    font-weight: normal;
}
.main-register{
     box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    padding: 6px 0 20px;
}
    .main-register_title {
    position: absolute;
    left: 0;
    top: 0;
    height: auto;
    text-align: center;
    line-height: 22px;
    padding-top: 10px;
    color: #fff;
    padding-left: 0;
    padding-bottom: 10px;
    background: #4E65A3;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    z-index: 2;
}
   
 
   .download{
        float: left;
    border: 1px solid #e5e7f2;
    background: #FFF;
    width: 100%;
    padding: 10px 20px 10px 20px;
    border-radius: 4px;
    color: #919191;
    font-size: 12px;
    -webkit-appearance: none;
    outline: none;
    overflow: hidden;
    z-index: 1;
    height: 39px;
    margin-bottom: 22px;
    cursor: pointer;
    position: relative;
    input{
    display:none;

    }
    img{
     position: absolute;
    top: 9px;
    width: 16px;
    right: 10px;
    }
   }

   .pdpad{
     padding-top: 8px;
    padding-bottom: 8px;

   }


   .main-register {
  float:left;
  width:100%;
  position:relative;
  padding:80px 0 20px;
  margin-bottom:50px;
    background: #fff;
  border-radius:6px;
  overflow:hidden;
  box-shadow: 0px 0px 0px 7px rgba(255,255,255,0.1);
}


}

@media only screen and (max-width: 1024px) {
    .login {
        .divform {
            padding: 0px 20px;
        }
    }
}
