@import "../../assets/sass/colors";
 
.header { 
    
    a{
    color:#FFF
    }
    .header__actions_menu{
     a{
         margin: 0px;
    width: 26px;
    border: 1px solid #FFF;
     }
     i{
             font-size: 22px;
     }
    }
    .ps-btn--black{
     color:#FFF;
     text-align:center
    }
    .ps-btn--black:hover{
     background:#CCC
    }
    .ps-logo img{
      width:auto;
      max-width:170px
    }
    .logo{
        height: 50px;
    }
    .header__content-right .header__actions{
        
    }
    .navigation .menu--market-2{

           margin: 0 ;
           width: max-content;
    }
    .header--market-place-2{
      background:#f5f5f5
    }
    .header__content-left{
          padding-top: 33px;
      img{width:36px}
    }
    .header__content-left {
    max-width: 100%;
    padding-right: 0px;
    display: flex;
    align-items: center;
    span{
     font-size: 15px;
    color: #3d3d3d;
    font-family: "Work Sans", sans-serif;
    font-weight: normal;
    padding-left: 10px;
    }
}




    .priceh{
        position: absolute;
        top: 36px;
        right: 5px;
        font-size: 9px;
        color: #ff914d;
    }
    .navbasket{
     top:-10px;
    }
    .ps-block--user-header{cursor:pointer}
    .navbasketmobile{
        display:none;
        }
   
   .ps-form--search-mobile input{
          background: #FFF;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
   }
   .ps-form--search-mobile button{
       
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
   }
   .ps-toggle--sidebar{

         border: 1px solid #FFF;
    border-radius: 4px;
    height: 26px;
    margin-left:20px
   }

}


@media only screen and (max-width: 600px) {
 .col-title-home{
      margin-top: 50px !important;
      h1{     font-size: 29px !important;}
 }
  .psywomenright{
     margin-top:40px
    }
}

@media only screen and (max-width: 1024px) {
    .header {
    }


    .panelfooter{
    text-align: center;
     img{

     }
     p{
margin-top:15px
     }
     a{
            background: #000;
    padding: 7px;
    color: #FFF;
    border-radius: 10px;
     }
    }

    .menu--mobile .accordion ul{
      padding-left:50px;
      li{
         padding-bottom:10px;
         font-size: 16px;
      }
    }
    .menu--mobile .accordion div{
         position: relative;
    z-index: 10;
    display: block;
    padding: 15px 20px;
    line-height: 20px;
    font-size: 15px;
    color: #000;
    }
    .menu--mobile .accordion div::after{
 
    background-image: url(../../../public/images/rowbtm.png);
    flex-shrink: 0;
    width: 20px;
    position: absolute;
    height: 20px;
    margin-left: auto;
    content: "";
     
    background-repeat: no-repeat;
    background-size: 2.25rem;
    transition: transform 0.2s ease-in-out;
    right: 25px;
    font-size: 26px;
    
    }
    .icomobileheader{
         display: block;
    background: #fefefe;
    border-radius: 50%;
    box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
      -webkit-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
       -moz-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
       height: 32px !important;
       margin-bottom:6px;
       margin-left:10px !important;
       img{
        width: 16px;
       }
    }
    .navbasketmobile{
    left:57px;
    display:block !important;
    }
    .priceh{
        position: absolute;
        top: 36px;
        right: 13px !important;
        font-size: 9px;
        color: #ff914d;
    }
    .navbasket{
        display:none !important;
       }
    .basket {
    top: -6px !important;
    right: 4px !important;
    }
}

@media only screen and (max-width: 1024px) {
    
    body{
      background:#FFF !important
    }

    .header .header__extra {
  display: inline-block;
  position: relative;
  width: 25px;
  height: 25px ;
  transition: all .4s ease;
}
    .mask{
     background: #000;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    z-index: 9999;
    opacity: 0.5;
    padding-top:'10%'
    }

    .header--mobile .navigation--mobiletoto{
       box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
      -webkit-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
       -moz-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
      }
      .header--mobile .navigation--mobile .ps-logo img {
         width: 170px;
     }
      .header--mobile .navigation--mobile .ps-goback img {
         width: 17px;
             padding-top: 8px;
    padding-bottom: 8px;
     } 
     .navigation--mobile{
       .navigation__left,.navigation__right{width:100px;}
     }

      .header--mobile .icon-menu {
         font-size:25px;
     }
     .header--mobile .header__actions > * {
    
    margin-right: 0px;
}
#menu-mobile .ps-panel__header button{float: right;
    font-size: 18px;
    position: absolute;
    right: 19px;
    top: 12px;z-index: 9999;}


}
