@import "../../assets/sass/colors";

.listtherapeutes {


input {
    position: relative;
}

input[type="date"]::-webkit-calendar-picker-indicator,input[type="time"]::-webkit-calendar-picker-indicator {
    background-position: right;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
    height:40px;
    width: auto;
}
 .selectth{

  -webkit-appearance: none;
  appearance: none;
          text-align: left;
    background-color: #FFF;
    border-radius: 30px;
    padding: 13px 15px;
    color: #46c3cc;
    font-weight: 600;
    position: relative;
    padding-right: 40px;
    margin-left: 20px;
    border: 0px;
    font-size: 15px;
    background-repeat: no-repeat;
    background-position-x: 98%;
    background-position-y: 10px;

 }
 .selectdepthss{
  position:relative;
 }
 .selectdepthss::after {
  content: "▼";
  font-size: 1rem;
  top: 6px;
  right: 15px;
  color:#46c3cc;
  position: absolute;
}
   .sectionfiltre{
     background: #e8e8e8;
    padding-top: 10px;
    padding-bottom: 6px;
     h1{
       font-size: 17px;
    color: #008189;
    height: 100%;
    margin-top: 15px
     }
     .formflex{
          display: flex;
    justify-content: flex-end;
    align-items: center;
    min-height: 72px;
     }
     .item{
      margin-left: 12px;
     }
     button{
      border-radius: 30px;
     }
     .inpfilter{

      width: 190px;
      text-align: center;
      background: #FFF;
      border-radius: 30px;
      padding: 13px 15px;
      color: #46c3cc;
      font-weight: 600;
      position: relative;
    }

   }

   .therapeutescontent{
   margin-bottom:20px;
     -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);

             border: 1px solid #e6e6e6;
    transition: .3s ease-in-out;
    background-color: #fff;
    border-radius: 10px;
    background: #FFF;
    padding: 20px;

    .ps-product--detail{
      margin-bottom:0px !important;
    }
    .listtags{
       display: block;
      .item{
                border: 1px solid #cbcbcb;
                border-radius: 35px;
                padding: 2px 14px;
                margin-right: 10px;
                font-size:12px;
                min-width: 159px;
                text-align: center;
                    margin-bottom: 10px;
    float: left;
      }
    }

    p {
      text-align: justify;
      overflow: hidden;
      height: auto!important;
      max-height: none!important;
      -webkit-line-clamp: initial!important;
      max-width: 100%;
      white-space: pre-line;
      font-size: 13px;
      margin-top: 20px;
    }
     .flexitems{
      display: flex;
      align-items: center;
     }
     .itemimg{

      width: 130px;
    height: 130px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;

     }
     .itemheader{
     }
     h3{
     margin-bottom:5px !important;
     }
     .actionrdv{
          flex: 0 0 auto;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    i{
          font-size: 30px;
    color: #46c3cc;
    margin-bottom: 19px;
    }


     }
   }
}


@media only screen and (max-width: 1024px) {
    .listtherapeutes {

    }

}