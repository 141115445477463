@import "../../assets/sass/colors";

 
.storiessection{
   .item{
       width: 30% !important;
       margin-right:0px !important;
    p{
          font-size: 12px !important;
    margin-top: 10px !important;
    margin-bottom: 20px;
    text-align:center;
    }
   }
}

@media only screen and (max-width: 900px) {
    
}
