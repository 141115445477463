@import "../../../assets/sass/colors";

.vosventes {
     .formsoldpauy {

     .actiftype{
           border-bottom: 3px solid;
     }
     
       .e9039{

       margin-bottom:30px;
       }
        .typepaiement{

            display: flex;
    justify-content: center;
    align-items: center;



          a{
              margin-left: 5%;
    margin-right: 5%;
    width: 50%;
    margin-bottom: 10px;
          }
           img{
             width: 225px;
           }
        }
     }
}

.footermodflex{     width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;}

@media only screen and (max-width: 1024px) {
 
 
}
