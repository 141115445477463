@import 'fonts';
@import 'colors';
@import "~bootstrap/scss/bootstrap";
@import 'forms';
@import 'buttons';
@import 'spaces';
@import 'nav';


body{
   
    font-size: 14px;
    color: $text-color;
    background-color: #f1f1f1;
}

h1,h2,h3,h4,h5{
    font-family: 'VarelaRound-Regular',sans-serif !important;
}

* {
  font-family: "LouisGeorgeCafe", sans-serif;
  font-weight: 400;
}


.dropdown-toggle::after{
    width: 12px;
    height: 10px;
    background-image: url(../../assets/images/chevron.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border: none;
    vertical-align: middle;
    margin-left: 14px;
}

.bg-light{
    background-color: $background;
}
.avatar{
    width: 66px;
    height: 66px;
    border-radius: 50%;
    background-color: $grey5;
    background-image: url(../../assets/images/default.svg);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    &.lg{
        width: 107px;
        height: 107px;
        cursor: pointer;
    }
}


a{
    text-decoration: none;
}

a{
    position: relative;
    .basket{
        position: absolute;
        top: -10px;
        right: 0;
        background-color: $primary;
        min-width: 20px;
        min-height: 20px;
        border-radius:50%;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
    }
}

html {
    font-family: sans-serif;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
}

body {
    margin: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
    display: block;
}

audio,
canvas,
progress,
video {
    display: inline-block;
}

audio:not([controls]) {
    display: none;
    height: 0;
}

progress {
    vertical-align: baseline;
}

template,
[hidden] {
    display: none;
}

a {
    background-color: transparent;
    -webkit-text-decoration-skip: objects;
}

a:active,
a:hover {
    outline-width: 0;
}

abbr[title] {
    border-bottom: none;
    text-decoration: underline;
    text-decoration: underline dotted;
}

b,
strong {
    font-weight: inherit;
}

b,
strong {
    font-weight: bolder;
}

dfn {
    font-style: italic;
}

h1 {
    font-size: 2em;
    margin: 0.67em 0;
}

mark {
    background-color: #ff0;
    color: #000;
}

small {
    font-size: 80%;
}

sub,
sup {
    font-size: 75%;
    line-height: 0;
    position: relative;
    vertical-align: baseline;
}

sub {
    bottom: -0.25em;
}

sup {
    top: -0.5em;
}

img {
    border-style: none;
}

svg:not(:root) {
    overflow: hidden;
}

code,
kbd,
pre,
samp {
    font-family: monospace, monospace;
    font-size: 1em;
}

figure {
    margin: 1em 40px;
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible;
}

button,
input,
select,
textarea {
    font: inherit;
    margin: 0;
}

optgroup {
    font-weight: bold;
}

button,
input {
    overflow: visible;
}

button,
select {
    text-transform: none;
}

button,
html [type="button"],
[type="reset"],
[type="submit"] {
    -webkit-appearance: button;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
    border-style: none;
    padding: 0;
}

button:-moz-focusring,
[type="button"]:-moz-focusring,
[type="reset"]:-moz-focusring,
[type="submit"]:-moz-focusring {
    outline: 1px dotted ButtonText;
}

fieldset {
    border: 1px solid #c0c0c0;
    margin: 0 2px;
    padding: 0.35em 0.625em 0.75em;
}

legend {
    box-sizing: border-box;
    color: inherit;
    display: table;
    max-width: 100%;
    padding: 0; /* 3 */
    white-space: normal;
}

textarea {
    overflow: auto;
}

[type="checkbox"],
[type="radio"] {
    box-sizing: border-box;
    padding: 0;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
    height: auto;
}

[type="search"] {
    -webkit-appearance: textfield;
    outline-offset: -2px;
}

[type="search"]::-webkit-search-cancel-button,
[type="search"]::-webkit-search-decoration {
    -webkit-appearance: none;
}

::-webkit-input-placeholder {
    color: inherit;
    opacity: 0.54;
}

::-webkit-file-upload-button {
    -webkit-appearance: button;
    font: inherit;
}

.table-responsive {
    @media (min-width: 1200px) {
        overflow-x: initial;
    }
}

.ps-table {
    thead {
        > tr {
            > th {
                 
                font-size: 18px;
                font-weight: 700;
                color: #515356;
                text-transform: uppercase;
                border-bottom: 1px solid #e5e5e5;
            }
        }
    }

    tbody {
        > tr {
            > td {
                vertical-align: middle;
                padding: 20px 30px;
                border: 1px solid #ddd;
                color: $text-color;
            }
        }
    }
}

.ps-table--responsive {
    @media (max-width: 768px) {
        border: none;
        max-width: 100vw;
        
        .price{display:none !important}
       
       
        .ps-product--cart {
            flex-flow: row;
            align-items: flex-start;
            margin-left: 5px;

            .ps-product__thumbnail {
                     display: block;
                    float: left;
                    width: 70px;
                    margin-right:10px;
            }
            .variations{
              font-size: 11px;
            }


            .ps-product__content {
                padding-left: 0;
            }
        }

        thead {
            display: none;
        }

        tbody {
            display: block;

            tr {
                display: block;
                margin-bottom: 10px;
                border: 1px solid #ddd;

                td[data-label] {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    border: none;
                    text-transform: capitalize;
                    border-top: 1px solid #ddd;
                    min-width: 100px;

                    &:before {
                        content: attr(data-label) ":";
                        font-weight: 600;
                    }

                    &:first-child {
                        border-top: none;
                    }
                }
            }
        }
    }
}

.ps-table--specification {
    tbody {
        tr {
            td {
                &:first-child {
                    background-color: #f4f4f4;
                    font-weight: 500;
                  
                    width: 150px;
                }
            }
        }
    }
}

.ps-table--faqs {
    background-color: #fff;

    h4 {
        
    }

    tr {
        td {
            border: none;
            padding: 30px 15px;

            &.question {
                
                font-size: 16px;
                width: 300px;
                font-weight: 500;
            }

            &.heading {
                width: 270px;
            }
        }
    }
}

.ps-table--compare {
    table-layout: fixed;

    tbody {
        tr {
            td {
                padding: 20px 30px;

                .price {
                    &.sale {
                        

                        del {
                            
                        }
                    }
                }

                .ps-btn {
                    width: 100%;
                    text-align: center;
                }

                .in-stock {
                    color: #89aa01;
                    font-weight: 600;
                }

                .out-stock {
                    font-weight: 600;
                }

                .sold-by {
                    text-decoration: underline;
                    color: #0066cc;

                    

                }

                &.heading {
                    color: #000000;
                    font-weight: 600;
                    text-align: center;
                    text-transform: uppercase;
                    background-color: #f4f4f4;
                }
            }
        }
    }

  
        tbody {
            tr {
                td {
                    min-width: 300px;
                }
            }
        }
    
}

.ps-table--whishlist {
    thead {
        tr {
            th {
                background-color: #f2f2f2;
                text-transform: uppercase;
       
                text-align: center;
                font-weight: 600;

                &:nth-child(2) {
                    text-align: left;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 30px 10px;
                vertical-align: middle;
                font-size: 16px;

                &:last-child {
                    text-align: right;
                }
            }
        }
    }

   
        tbody {
            tr {
                td {
                    min-width: 200px;

                    &:first-child {
                        max-width: 50px;
                        min-width: 50px;
                        text-align: center;
                    }

                    &:nth-child(2) {
                        min-width: 300px;
                    }
                }

            }
        }
    
}

.ps-table--shopping-cart {
    thead {
        tr {
            th {
                padding: 15px 20px;
                text-transform: uppercase;
            
                text-align: center;
                font-weight: 600;
                background-color: #f2f2f2;
                border: none;

                &:first-child {
                    text-align: left;
                }
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 20px 10px;
                vertical-align: middle;
                font-size: 16px;

                .form-group--number {
                    max-width: 100px;
                    display: inline-block;

                    input {
                        border-radius: 0;
                    }
                }

                .ps-product--cart {
                    max-width: 500px;
                }

                &:last-child {
                    text-align: right;

                    a {
                        font-size: 20px;
                    }
                }
            }
        }
    }


}

.ps-table--product-groupped {
    .ps-tag--out-stock {
        color: red;
        font-weight: 500;
    }

    .ps-btn {
        padding: 15px 20px;
        border-radius: 0;
    }

    .title {
        font-size: 16px;
    }

    tbody {
        tr {
            vertical-align: middle;

            td {
                border: none;
                vertical-align: middle;

                &:first-child {
                    width: 150px;
                }
            }
        }
    }

  
        .title {
            font-size: 14px;
        }
        tbody {
            tr {
                td {
                    min-width: 200px;
                }
            }
        }
    

    @media (max-width: 1680px) {
        .ps-tag--out-stock {
            span {
                display: block;
            }
        }
    }
}

.ps-table--vendor {
    thead {
        tr {
            th {
                padding: 10px 16px;
                font-size: 14px;
                font-weight: 600;
          
                background-color: #f4f4f4;
            }
        }
    }

    tbody {
        tr {
            td {
                padding: 8px 16px;
              

                a {
                   
                    color: #0066cc;

                   
                }

                strong {
                    text-transform: uppercase;
                    
                }
            }
        }

    }
}

.ps-table--vendor-status {
    tbody {
        tr {
            td {
                 

                strong {
                  
                    text-transform: uppercase;
                    font-weight: 600;
                }

                &:first-child {
                    background-color: #f4f4f4;
                }
            }
        }
    }
}

.ps-table--notification {


    tbody {
        tr {
            td {
                &:first-child {
                    max-width: 250px;
                    width: 50%;
                }
            }
        }
    }
}

@media (min-width: 992px){
    
    .container {
      max-width: 960px !important;
    }


}

 
