@import "../../../assets/sass/colors";

.colstories {

.colstoriesflex{
  display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px !important;

  .item{
  background: #ececec;
    border-radius: 12px;
    padding: 10px;
    width: 180px;
    height: 110px;
    label{

    }
    img{
     width: 64px;
    display: block;
    margin: 0 auto;
    margin-bottom: 7px;
    }

  }
}

.storiescols{
  display: block;
    justify-content: space-between;
    align-items: center;
    margin-top: 25px !important;

      .item{
    margin-bottom:20px;
   background: #f9f9f9;
    border-radius: 12px;
    padding: 10px;
    width: 100%;
    display: flex;
    height: 75px;
    justify-content: space-between;
    align-items: center;
    label{
         color: #f96161;
    font-size: 18px;
    border: 1px solid;
    width: 28px;
    border-radius: 50%;
    }
    img{
         border-radius: 50%;
    width: 52px;
    height: 52px;
 
    }

  }
}





}

@media only screen and (max-width: 900px) {
    

.colstories {

.colstoriesflex{
 

      .item{
      background: #ececec;
        border-radius: 12px;
        padding: 10px;
        width: 150px;
        height: 110px;
     

      }
      }
    }
}
