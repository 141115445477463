@import "../../assets/sass/colors";


.copyright{
      color: #FFF;
    font-size: 12px;
    text-align: center;
}

  .icobags{
    position: absolute;
    top: 0px;
    right: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: top;
    width: 20px;
    height: 20px;
    color: white;
    background-color: #119ea6;
    border-radius: 50%;
    i{
      color: rgb(255, 255, 255);
    font-weight: 700;
        font-size: 12px !important;
    font-style: normal;
    line-height: 1em;
    }
    }
.nav-mobile-778 a{

      color:#000;
      width: 20%;
      span{
       font-size: 12px;
      }



}
.nav-mobile-778 a.iwico{



      img{
             width: 30px;
             height: 30px;

      }

}

.menu--mobile > li > a{
    color:#000
}
.navigation--list .navigation__item:hover{color:#000}

   .bt_bb_section_top_section_coverage_image {
    position: absolute;
    right: 0;
    left: 0;
    z-index: 2;
    pointer-events: none;
        top: -4px;
}
footer {
    padding: 20px 0;
         background: #6feaf2;
    background: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #6feaf2 100%);
   padding-top: 120px;
    position:relative;
    .logo{
        height: 40px;
    }



    .social-twitter img{
         position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    }

    ul.ps-list--link li::before {
    font-family: "FontAwesome";
    content: "\f105";
    background: none;
    color: #85e8ef;
    top: -7px;
    margin-right: 15px;
    width: 14px;
    height: 14px;
}
.footerContants {
    position: relative;
    margin-bottom: 13px;
}
.footerContants i.fa {
    position: absolute;
    top: 3px;
    left: 0;
    font-size: 20px;
    color: #85e8ef;
}
.footerContants a, .footerContants p {
    color: #FFF;
    padding-left: 36px;
    font-weight: 700;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
}

.padtopelm990{
      padding-top: 44px;
}

.socialWrapper ul li {
    display: inline-block;
    list-style: none;
}
.socialWrapper a {
    font-size: 14px;
    display: inline-block;
    padding: 14px;
    border: 1px solid #cceff2 !important;
    border-radius: 100%;
    position: relative;
    color: #85e8ef;
    margin: 0 4px!important;
    -webkit-transition: all 150ms linear;
    transition: all 150ms linear;
}
.socialWrapper a i.fa {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}



    .mt5{margin-top: 0px !important;}
    a{
        margin-right: 20px;
        color: #FFFFFF;
        font-size: 14px;
    }
    .waping-list{
        padding: 0;
        margin: 0;
        list-style: none;
    }
    .menu-item-object{
        width:26px;
    }
    .footer-container{
        color:#FFFFFF;
    }
    .phrasefooter{
        color:#000;
    }

}
@media only screen and (max-width: 800px) {

.home .section2 h2{

}
.ps-footer__widgets{
   display:block !important;
}
footer .padtopelm990 {
    padding-top: 0px;
    padding-bottom: 0px;
}

footer .widget-title::before{
     float: right;
     content: '';
      display: inline-block;
      width: 12px;
      height: 9px;
      background: #f5f2ed;
      -webkit-transition: all .3s ease;
      transition: all .3s ease;
      -webkit-transform: rotate(0);
              transform: rotate(0);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
      -webkit-mask-position: center;
      -webkit-mask-repeat: no-repeat;
      -webkit-mask-size: contain;
      -webkit-mask-image: url("data:image/svg+xml,%3Csvg version='1.1' id='Layer_1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' width='12px' height='9px' viewBox='0 0 20 14.5' enable-background='new 0 0 20 14.5' xml:space='preserve'%3E%3Cpolygon fill='%235E5547' points='0,0 20,0 10,14.5 '/%3E%3C/svg%3E");
    }
    .widget_footer{
     max-width: 100%;
    }
    h4.widget-title::after{
      width:100%
    }
    .ps-list--link, .pul33{
      display:none
    }
      .displ_9987{
    display:block !important;
     -webkit-transition: all 150ms linear;
      transition: all 150ms linear;
  }
  .tcenter{
       text-align: center;
  }
}
@media only screen and (max-width: 1024px) {

    .shopicon{}
    .footercontent{

      .navigation--list{
           padding: 8px 0px;
      }
    }
    .ps-panel--sidebar {
    width: 90%;

   }
   .shopicon{
           background: #000;
    border-radius: 50%;
    width: 39px;
    height: 39px;
    display: block;
    margin: 0 auto;
    img{
      width: 23px;
   }
   }


   .nav-mobile-778{
     box-shadow: 0px -3px 7px -2px rgba(59,59,59,0.49);
-webkit-box-shadow: 0px -3px 7px -2px rgba(59,59,59,0.49);
-moz-box-shadow: 0px -3px 7px -2px rgba(59,59,59,0.49);
  .overscroll{
      overflow: auto;
    }
   }
}

#videos {
  position: relative;
  width: 100%;
  height: 100%;
  margin-left: auto;
  margin-right: auto;
}

#subscriber {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}

#publisher {
  position: absolute;
  width: 360px;
  height: 240px;
  bottom: 10px;
  left: 10px;
  z-index: 100;
  border: 3px solid white;
  border-radius: 3px;
}
