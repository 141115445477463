@import "../../../assets/sass/colors";

.vosventes {
   
   .accordion-item:first-of-type .accordion-button{
    font-size: 13px;
   }

   .accordion-button:not(.collapsed) {
    color: #004e4a;
    background-color: #e5f5f6;
     
}
.accordion-button{ background:#EEE}

}

 

@media only screen and (max-width: 1024px) {
 
 
}
