@import "../../assets/sass/colors";

.menudashright{
  background: #ffffff;
   border-radius: 1.25rem;
     -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
             padding: 24px !important;
}
.menudash {

.menudashitems {
  background-color: #edfeff;
  border-radius: 1.25rem;
   -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
}
.card-title {
    color: #000;
  
}
.nav-link:hover, .nav-link:focus {
    color: #46c3cc !important;
}
.mob-nav-content-btn {
    background: #eee;
    padding: 8px;
    text-align: left;
    border-radius: 6px;
    display:none;
    margin-bottom:10px;
}
   .card-img-top{
       width: 200px;
       margin: 0 auto;
    }
    .logout{
    text-align:center;
    margin-top:15px;
    margin-bottom:15px
    }
    .logout_btn {
   
    position: relative;
    padding: 10px 30px;
    border: none;
   
    color: #fff;
    border-radius: 4px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: #495057;
}
}

@media only screen and (max-width: 1024px) {
    .menudash {
     .mndbnone{display:none}
     .mob-nav-content-btn {
      display:block !important;
     }
  
    }
}
