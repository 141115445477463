@import "../../assets/sass/colors";

.finconsultation {
 .itemimg{
     background-position: 50%;
    background-size: cover;
    border-radius: 50%;
 }
 .tabsreserv{
   .Mui-selected{color: #0297a2;}
   .MuiTab-textColorPrimary {
  
    font-family: "Work Sans", sans-serif;
    font-weight: bold;
    font-size: 12px;
   
}
.MuiTabs-indicator{
  background-color: #0297a2;
 }
}
}
 

@media only screen and (max-width: 1024px) {
    .finconsultation > .section2 {

      margin-top:100px

    }

}

@media only screen and (max-width: 770px) {
    .finconsultation {


    .listtherapeutesppad{
     padding-right:10px;
     padding-left:10px;
    }

     
       .therapeutescontent{
         margin-left:0px !important;
          margin-right:0px !important;
       }

  .abonnement .col-md-4{
         margin-left:0px !important;
          margin-right:0px !important;
       }

        .abonnement .col-md-4 a{
               width: 100%;
       } 


    }

}