/*
  CSS grid is great for making video layouts.
  For the purposes of this demo, we're keeping things relatively simple.
  If you're interested in dynamic video grids, make sure to check out this blog post:
   https://www.daily.co/blog/add-pagination-to-a-custom-daily-video-chat-app-to-support-larger-meetings/
 */
.call {
  flex-grow: 1;
  position: relative;
}

@media only screen and (max-width: 600px) {
  .call {
    padding: 0;
    grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
    grid-auto-rows: unset;
  }
}

.is-screenshare {
  display: grid;
  padding: 5rem;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  grid-row-gap: 15px;
}

.is-screenshare .self-view {
  width: 240px;
  height: 135px;
}

.is-screenshare .tile-video {
  width: 240px;
  height: 135px;
}

.info-box {
  background: #43c3cc;
  color: var(--white);
  width: 480px;
  max-width: 80%;
  margin-left: 50%;
  transform: translateX(-50%);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 3rem;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 25px;
}

@media only screen and (max-width: 600px) {
  .info-box {
    width: 400px;
    height: auto;
    padding: 2.5rem 1rem;
  }
}

.room-url {
  padding: 0 1rem;
  word-break: break-word;
  font-weight: normal;
  font-size: 12px;
}
