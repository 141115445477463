@import "../../../assets/sass/colors";
.listing {
.activestore{
  border: 1px solid #918e8e;
}
.activestore2{
   border-bottom: 2px solid #918e8e;
}

 .surcmd334{ 
     border: none;
        font-weight: bold;
    font-size: 11px;
    padding: 15px 5px;
    margin-bottom:20px;
    font-family: 'Montserrat-Medium';}
.activebgtq{display:none}
 .bigboutiques{
  margin-bottom: 23px;
 .b9009{
     height: 100%;
   display: flex;
    flex-flow: row;
    align-items: center;
    h4{
     font-family: 'Work Sans';
    }
 }

 .list-items{
  display:flex;
  flex-flow: row nowrap;
    justify-content: flex-end;
    align-items: center;
 }
  .item{
    background: #eee;
    width: 90px;
    height: 90px;
    border-radius: 50%;
    padding: 10px;
    text-align: center;
    margin-right:10px;
    margin-left:10px;
    a{
     display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px;
    }
    img{
     width:80%
    }
  }
 }
}

@media only screen and (max-width: 1024px) {
 .listing{
    .activebgtq{display:block !important}
    .bigboutiques {
      display:none;
      .b9009 {
		    
		    align-items: center;
		    justify-content: center;
		    margin-bottom: 12px;
		}
		.list-items {
    
			    justify-content: center !important;
			    align-items: center;
			}
	.item{
	  height:65px
	}
   }
 }
  
}
