@import "../../assets/sass/colors";
.categories {
 .surcmdpalat{
   display:none;

 }

  .surcmdpalatweb{
margin-bottom: 20px;
    display: block !important;
    background-color: #497ec4;
    width: 100%;
    min-height: 150px;
    background-repeat: no-repeat;
    background-position: 31%;
    background-size: 100px;
    position: relative;
    background-image:url(../../../public/images/surcommandepalet.jpg);
   
    .item{
          height: 100%;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    }
    h4{
    font-family:'Montserrat-Medium';
    border-radius: 7px;
    width: max-content;
    background: #1458b3;
    padding: 10px;
    color: #FFF;
    margin: 0 auto;
    }
 }

}

@media only screen and (max-width: 1024px) {
 
 .categories {
 .surcmdpalat{
 margin-bottom:20px;
    display:block !important;
        background-color: #497ec4;
    width: 106%;
    margin-right: -3%;
    margin-left: -3%;
    min-height: 85px;
    background-image:url(../../../public/images/surcommandepalet.jpg);
        background-repeat: no-repeat;
    background-position: 13px;
    background-size: 50px;

    position: relative;
    .item{
          height: 100%;
    position: absolute;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    }
    h4{
    font-family:'Montserrat-Medium';
    border-radius: 7px;
    width: max-content;
    background: #1458b3;
    padding: 10px;
    color: #FFF;
    margin: 0 auto;
    }
 }
}
}
