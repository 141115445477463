@import "../../assets/sass/colors";

.dayli {

  .postophideader {
    width: 100%;
    padding-top: 10px;
    top: 0px;
  }

  .secty8890 {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .compteur {
      color: #FFF;
      font-family: 'Linearicons';
      font-weight: bold;
      letter-spacing: 1px;
      font-size: 15px;
    }
  }

  .title {
    font-size: 13px;
    font-weight: normal;
    text-transform: capitalize;
    color: #353535;
    font-family: 'Work Sans';
    text-align: center;
  }

  .colmin {
    padding: 0;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 100vh;
    background: white;
    top: 0px;
    display: flex;
    flex-direction: column;

    .username {
      background-color: #80adb2;
    }
  }
  .app {
    background-color: var(--darkest-blue);
    min-height: 100vh;
    display: grid;
    /* this is where we're dividing our app in 3 rows: header, call, and tray. The meeting information
    is outside the app's document flow.*/
    grid-template-rows: 4rem 1fr 4rem;
  }

  p {
    font-size: 16px;
    margin: 16px;
    text-align: center;
  }

  h1 {
    margin: 0;
    padding: 0;
    font-weight: 500;
    color: var(--turquoise);
  }

  p.small {
    color: var(--dark-grey);
    font-size: 12px;
  }

  button {

    border: none;
    border-radius: 8px;
    font-size: 12px;
    font-weight: 600;
    cursor: pointer;
    padding: 12px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 4px;
  }

  .api-error {
    background: var(--dark-blue-border);
    width: 480px;
    height: 270px;
    display: inline-flex;
    flex-direction: column;
    align-self: center;
    justify-self: center;
    text-align: center;
    padding: 3rem;
    box-sizing: border-box;
    border-radius: 4px;
  }

  .api-error p {
    color: var(--white);
  }

  .api-error h1 {
    color: var(--red-dark);
  }

  .api-error a,
  .api-error a:visited {
    color: var(--white);
  }
  .home-screen {
    padding: 0 1rem;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    justify-content: center;
    text-align: center;
  }

  .home-screen h1 {
    color: var(--white);
    font-weight: bold;
  }

  .home-screen p {
    color: var(--grey);
  }
}


@media only screen and (max-width: 1024px) {
  .dayli {

  }

}