@import "../../assets/sass/colors";
.coloractive{
        border: 1px solid #d7d7d7 !important;
    border-radius: 17px;
}
.categories {
.bigcategories,.headerpageimg{
     display:none;
}


.tailactive::before{
     background:#000 !important
}
._35LeV{padding:3px;border:1px solid #FFF}

 .headerpagecateg{
    display:none;
}


    .headerpagecategweb{
    display:flex;
     justify-content: space-around;
     float:left;
         padding-top: 12px;
    .item{
    padding-right:30px;

      h4{
     font-weight: bold;
     cursor:pointer;
    padding-bottom: 10px;
    font-family: "Work Sans", sans-serif;
      }
       h4.active{
        border-bottom: 3px solid #000;
       }

    }
}


.headerpageimg{
         background-repeat: no-repeat;
    min-height: 160px;
    background-position: center;
    background-size: cover;
    width: 106%;
    margin-bottom: 20px;
    margin-top: 20px;
    margin-left: -3%;
    margin-right: -3%;
}
   .section2{
    padding: 50px 0;
   }

   .single-banner {
    display: flex;
    align-items: center;
  }

  .float-start {
    float: left!important;
}
.padding-0 {
    padding: 0;
}
.banner-photo {
    
}
.banner-photo a {
    display: block;
    position: relative;
        min-height: 280px;
        padding:25px 53px 66px;
        span{    font-weight: 700;text-transform: uppercase;}
}
.banner-photo a:hover::before {
    transform: scale(1);
    opacity: 0.2;
}

.banner-photo a {
    transition: all 0.3s ease 0s;
    -webkit-transition: all 0.3s ease 0s;
    text-decoration: none;
    color: #323232;
}
.banner-photo a::before {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    bottom: 18px;
    content: "";
    left: 18px;
    position: absolute;
    right: 18px;
    top: 18px;
    transform: scaleY(0);
    transition: 0.5s;
    opacity: 0;
}
img {
    max-width: 100%;
    height: auto;
}

.banner-photo a::after {
    background: rgba(0, 0, 0, 0.5) none repeat scroll 0 0;
    bottom: 18px;
    content: "";
    left: 18px;
    position: absolute;
    right: 18px;
    top: 18px;
    transform: scaleX(0);
    transition: 0.5s;
    opacity: 0;
}
.banner-brief.banner-brief-right {
    transform: translateX(-15px);
    -webkit-transform: translate(-15px);
    -ms-transform: translate(-15px);
    -o-transform: translate(-15px);
}
.banner-brief {
    border: 1px solid #666;
    padding: 25px 53px 66px;
    z-index: 9;
    a{
         min-height: 158px;
    display: block;
    }
}
.banner-brief h2 {
    font-size:18px;
    font-weight: 300;
    line-height: 40px;
    margin-bottom: 0;
    padding-bottom: 10px;
    color: #333;
    text-transform: uppercase;
}
.banner-brief h2 span {
    font-weight: 700;
}
.banner-brief h2.line-bottom {
    font-size: 24px;
}
.line-bottom {
    position: relative;
}
.shop-now {
    border: 1px solid #f6416c;
    color: #333333;
    display: inline-block !important;
    font-size: 14px;
    height: 33px;
    line-height: 31px;
    margin-top: 20px;
    padding: 0 13px;
    text-transform: uppercase;
}
.bgimg6{
         background-repeat: no-repeat;
    
    background-size: cover;
}
.opatitle{
    background: #FFF;
    opacity: 0.7;
    border-radius: 10px;
    width: max-content;
    padding: 10px;
}
.filtrerightmobile{display:none}

}

@media only screen and (max-width: 1024px) {
    .categories {
    .flitres{display:none;}
    .headerpageimg{display:block}
    .filtredropdown{
               
                left: 7px;
                z-index: 1000;
                min-width: 97%;
                padding-top: 2px;
                transition: all .3s ease;
                display: none;

    }
    .headerpagecategweb{display:none}

    .headerpagecateg{
    display:flex;
     justify-content: space-around;
     margin-bottom: 16px;
    .item{

      h4{
     
    font-weight: normal;
    padding-bottom: 10px;
    font-family: "Work Sans", sans-serif;
      }
       h4.active{
        border-bottom: 3px solid #000;
       }

    }
}
    .activefiltre{
      display:block !important;
      transition: all 3.3s ease;
    }
    .filtrerightmobile{
       display:block;overflow: hidden;
       .select-filtre{
            text-align: center;
            div{
                  border: 1px solid #CCC;
                    border-radius: 50%;
                    width: 25px;
                    height: 25px;
                    padding-top: 1px;
                    margin: 0 auto;
            }
       }
       .orderBy{width:15%}
       .form-group--nest{
         margin-right:0px;
         width: 85%;
         input{
               background: #000;
                color: #FFF;
                height: 33px;
                border-radius: 20px;
         }
         div.ico{
          top: 5px;
         }
         i{
          color:#FFF;
         }
       }
    }
    .filtreright{display:none}
    .section2{padding-top:15px;}
    .cllgsect66{
     padding-left:0px;
     padding-right:0px;
    }
    .bigcategories{
        padding-bottom: 15px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
         margin-bottom:10px;
        .item{

        width: 80px;
        height: 80px;
        text-align: center;
        label{
              font-weight: bold;
                font-size: 10px;
                letter-spacing: -0.9px;
                text-transform: uppercase;
        }

          a{
              display: flex;
              
                width: 75px;
                height: 75px;
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                text-align: center;
             
              
          
            
            
          }
            .active{
                  width: 80px !important;
                 height: 80px !important;
                }
            .fashion{
                 
                 img{
               width: 100%;
            }
            }
            .afrocreation{
               
                 img{
               width: 100%;
            }
            }
             .ventout{
                 
                 img{
               width: 100%;
            }
            }
             .surcommande{
               
                 img{
               width: 100%;
            }
            }
        }
    }
    .banner-brief h2{
     line-height: 18px;
     text-transform: capitalize;
    }
    .section-title{
     display:none
    }
      .single-banner{
      display:block;
       .banner-photo{
         width:100%;
         display:block;
         float:none !important;
         a{
           min-height: 180px;
         }


       }
       .banner-brief{
         width:100%;
         display:block;
         float:none !important;
         padding:25px 50px 0px;
       }
      }
       .banner-brief.banner-brief-right{
            -webkit-transform:none !important;
      }
    }
}
