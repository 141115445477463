@import "../../assets/sass/colors";
.forget {
    min-height: 100vh;
    display: flex;
    align-items: center;
    .logo{
        height: 50px;
    }
    h3 {
        margin-bottom: 63px;
    }

    form {
        background-color: white;
        padding: 30px 60px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
        .btn {
            margin-top: 38px;
        }
          .btn:hover {
               
    background: #fcb800;
    border: #fcb800;
 
        }
    }
    p {
        color: $grey3;
    }
    a {
        color: $text-color;
        text-decoration: none;
    }
    .rights {
        margin-top: 50px;
        font-size: 12px;
        letter-spacing: 0;
    }
    .btn{
    }
}

@media only screen and (max-width: 1024px) {
    .login {
        form {
            padding: 15px 0px;
        }
    }
}
