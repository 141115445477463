@import "../../assets/sass/colors";
.flexrowtarif2 .item .pricebox label:after{
      width: 90px !important
}
.e99990{
   width: 100%;
    border-bottom: 1px solid #eee;
    margin-bottom: 41px;
}
.t99990{
 text-transform: uppercase;
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
}
.tests {
 .noshadowbox{
  border: 1px solid #CCC !important;
  box-shadow:none !important;
  margin-bottom:20px
 }


 label::after{
 display:none
 }
 .itemfl{
      display: flex;
    justify-content: flex-start;
    align-items: center;
 }
}

 .itemfl988938{
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    strong{
          margin-right: 10px;
             width: 10px;

    }
    input{
       margin-right: 2px;
       padding-right:30px;
       margin-top: 5px;

    }
    label{
         margin-top: 0px !important;
    }
    label:after{
     display:none !important;

    }
 }


@media only screen and (max-width: 1024px) {
    .tests {

    }

}