@import "../../assets/sass/colors";

.paypal-button-number-1{display:none !important}
.commande {
.separateur{
 position:relative
}
.separateur span.left{
    border-bottom: 1px solid;
    width: 40%;
    position: absolute;
    left: 0px;
    top: 8px;
}
.separateur span.right{
          border-bottom: 1px solid;
    width: 40%;
    position: absolute;
    right: 0px;
    top: 8px;
}
.separateur span::after{
   border:1px solid 
}
.separateur span::before{
      border:1px solid 
}
.se1mobile{display:none}
.ps-block--shopping-total{margin-bottom:10px}
.form-group{margin-bottom:15px}
.form-group > label{margin-bottom:10px;}
.form-group__content input{height: 40px !important;}
ps-block--shopping-total{margin-bottom:10px}
.ps-block--shopping-total h3 {
   
    font-size: 19px;
    
}
.separateur::after{
   
}
.separateur::before{
   
}
.pppay78{
       position: absolute;
    right: 14px;
    bottom: -7px;
}

.labelpay66{
     position:relative;
     height: 46px;
    }
    .labelpay67{
     position:relative;
    
    }
.nmert77{
        padding: 12px 20px 2px 31px;
    margin-bottom: 10px;
}
.ps-table--shopping-cart{
    border-bottom:1px solid #878787;
    margin-top: 20px;
}
.ps-block--shopping-total .ps-block__header{
    margin-bottom:8px;
}
.ps-block__content{margin-top:20px;}
.psproducttd{
     position:relative;
}
  .ps-product--cart{
    .ps-product__thumbnail{
       img{
             width: 70px;
              height: 70px;
       }
    }
    .ps-product__content{
     a{
      color:#666;
      font-size:14px;
     }
    }
   
    .plusdetail{
      font-size:12px !important;
    }
  }
  .ps-product--cart .ps-product__content {
    padding-left: 15px;
}
 .ps-product-total{
      position: absolute;
    bottom: 12px;
    right: 0;
    }

.checkout__address--content__header {
         margin-top: 20px;
}
.shipping__contact--box__list {
    display: table;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid #b1b1b1;
    border-radius: 10px;

}
.shipping__radio--input {
    display: table-cell;
    padding-right: 1.5rem;
}
.shipping__radio--label {
    display: table-cell;
    width: 100%;
}
.shipping__radio--label__primary {
    display: table-cell;
    width: 100%;
}
}

@media only screen and (max-width: 1024px) {
    .commande {
         .se1mobile{display:block;margin-top: 12px;}
          .order-1{display:none}
    }
}
