@import "../../assets/sass/colors";
.devenirvendeur {
     padding-top:50px;
        .react-tel-input .form-control{
         height: 48px;
    border: solid 0.3px #a9b6c4;
    background: rgba(244, 246, 251, 0.3);
    border-radius: 10px;
    width: 100%;
   }
.ps-btn--act{
     background: #000;
    color: #FFF;
    font-size: 14px;
    padding: 10px 20px;
}
.link{color: #1380c8;
    text-decoration: underline;}
      .item {
          width: 100px;
    height: 100px;
    text-align: center;
    margin: 0 auto;
    margin-bottom:10px;
  }
  .item .fashion {
    
}
.item .afrocreation {
     
}
.item a {
    display: flex;
    
    width: 100%;
    height: 100%;
    margin: 0 auto;
    align-items: center;
    justify-content: center;
    text-align: center;
    
    img{
     width: 100%;
    }
}
    .logo{
        height: 50px;
    }
    .type-con {
        border-radius: 20px;
        border: solid 0.3px $grey2;
        background-color: $grey;
        display: inline-flex;
        padding: 2px;
        .type {
            padding: 7px 25px;
            border-radius: 20px;
            font-size: 12px;
            cursor: pointer;
            transition: all 400ms;
            &.active {
                background-color: $green;
                color: white;
            }
        }
    }
    form {
        background-color: white;
        padding: 10px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
        .mb-3{margin-bottom:17px !important}
        .btn:hover {
    margin-top:10px;
    background: #fcb800;
    border: #fcb800;
 
        }
    }
    p{
        color: #262626;
    }
    a{
        color: $text-color;
        text-decoration: none;
    }
    button.ps-btn:hover{
      background:#000 !important
    }
}

@media only screen and (max-width: 1024px) {
    .devenirvendeur {
        form {
            padding: 5px 9px;
            .mb-3 .col{
                  width: 100%;
                display: block;
                flex: none;
                margin-bottom: 18px;

            }
        }
    }
}
