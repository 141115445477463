@import "../../../assets/sass/colors";

.parametres {
   .rowtitel {
    align-items: center;
    display: flex;
    margin-bottom: 20px;
}
.rowtitel img {
    width: 45px;
}

.rowtitel h3 {
    border: 0;
    color: #07345f;
    font-size: 15px;
    letter-spacing: -.5px;
    margin: 0 0 0 16px;
}

.profile-edit-container {
    border: 0;
    padding: 0 10px 10px;
}
.mrgbtm {
    margin-bottom: 12px;
}
.rowflex {
         display: flex;
}
 form {
    margin-bottom:20px;
        background-color: white;
        padding: 15px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
        overflow: hidden;
            display: flex;
    justify-content: space-between;
    align-items: center;
         select{
      -webkit-appearance: auto;
      border: 1px solid #e5e7f2;
    padding-top: 6px;
    padding-bottom: 6px;
    background: #FFF;
    border-radius: 4px;

}}

.MuiSwitch-colorSecondary.Mui-checked {
    color: #46c3cc;
}
.MuiSwitch-colorSecondary.Mui-checked+.MuiSwitch-track {
    background-color: #35ccd6;
}

}

@media only screen and (max-width: 1024px) {

    
}
