@import "../../assets/sass/colors";
   
    .modelhermix909{
     .modal-content{
        background: #111111 !important;
     }
     .modal-header{
      border:0px;
     }
     .contentstoris{
              display: flex;
              justify-content: center;
     }
    }
.platformes {
    min-height: 100vh;
    padding: 60px 0;
    padding-top: 0;
    .sectiontittleboutique{display:none}
    .stories{
  cursor:pointer
}
    .fmpflex90{
        
         display: flex;
    justify-content: flex-start;
    align-items: center;
    overflow-x: scroll;
    div{
    text-align:center
   
    }
      p{
          font-size: 12px;
     }
    .item{
          width: 100px;
    height: 100px;
    padding: 5px;
    background: #eee;
    margin-right: 10px;
      img{

      }
    }
    }



    .boutiquemeta{
          display: flex;
          flex-flow: row nowrap;
          justify-content: space-between;
          align-items: flex-start;
          .item{
             margin-right:10px;
             margin-left:10px;
          }

          .loves{
           div{

           }
           i{
            font-size: 29px;
             color: red;
           }
          }
    }



.w-32 {
    width: 8rem;
}
.relative {
    position: relative;
}
.h-32 {
    height: 8rem;
}
.rounded-full {
    border-radius: 9999px;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}
.rounded-full {
    border-radius: 9999px;
}
.bg-white {
    background-color: #fff;
}
  .conic-gradient:after {
  display: block;
  border-radius: 9999px;
  content: '';
  width: 111%;
  height: 111%;
  position: absolute;
  z-index: -1;
  left: -5%;
  top: -5%;
  background-image: conic-gradient(#c7287e, #c58ad2, #e597bf, #ffcb83, #d04c22);
  animation: spin 5s linear infinite;
}

@keyframes spin { 
  100% { 
    transform: rotate(360deg);
  }
}




    .stories{
      div{
        width: 60px;
    height: 60px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
     margin: 0 auto;
      }
    }

    .section1{
              background: #eee;
    padding-top: 41px;
    padding-bottom: 8px;
    }
    .menu--product-categories .menu__content {
    visibility: visible !important;
    opacity: 1 !important;
    transform: scale3d(1, 1, 1) translateY(0) !important;
    padding-top: 7px;
}
.menu--dropdown > li > a > i{display:none}
    .menu--product-categories .menu--dropdown{padding:0;background-color: #eee;
     border: 0}

     .menu--product-categories .menu--dropdown.active{display:block}


    .menu--dropdown > li {
       list-style: none;
    }
.ps-breadcrumb{  
    background: #eeeeee;}

.menu--product-categories .menu__toggle i {
 
    margin-left: 17px;
}
.section2{margin-top:20px}
    .attrs{
        border: 2px solid $grey5;
        background: #FFFFFF;
        box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.05);
        border-radius: 7px;
    }
    .alerttoast{
     position: fixed;
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    z-index:77777777;
    text-align: center;
    }
    #navlist
    {
      margin-bottom:20px;
    }
 
     .slick-prev {
           left: -20px; top: 10.5px;
           height: 20.9px; }
           .slick-next {  
                  right: -20px;
             height: 20.9px;top: 10.5px; }
    
   .slick-next,.slick-prev{
    background: #fb6c13;
    color: #FFFFFF;
   }
    .itemslicker{
        font-size:12px;

        padding:7px;
    }
    .hidden { display:none; }
    .slick-prev:hover, .slick-prev:focus, .slick-next:hover, .slick-next:focus {
        color: #FFFFFF;
        outline: none;
        background: #fb6c13;
    }
}

@media only screen and (max-width: 1024px) {
    .platformes {
     .sectiontittleboutique{display:block;}
     .sectiontittleboutique h2{    border: none;
    background: #000;
    color: #FFF;
    padding-top: 21px;;}
     .sectmobboutique{margin-top:0px; padding-top:0px}
    .boutiquemeta .item label{font-size:12px}
       .ps-product .ps-product__thumbnail {
         
            text-align: center;
        }
        .ps-product .ps-product__thumbnail img {
              width: 90%;
          }
           .menu--product-categories .menu--dropdown{display:none}
    }
}
