@import 'colors';
form{
    .form-label{
        font-family: 'LufgaMedium',sans-serif;
        font-size: 14px;
        color:$text-color;
        text-align: left;
        display: block;
    }
    .form-control {
        font-family: 'LufgaRegular',sans-serif;
        font-size: 14px;
        border: solid 0.3px $grey3;
        background-color: $grey;
        border-radius: 10px ;
        padding: 12px 14px;
        color: $grey3;
        transition: all 500ms;
        &::placeholder{
            color: $grey3;
        }
        &:focus{
            box-shadow: none;
        }
    }
    .form-check {
        text-align: left;
    }
}