@import "../../assets/sass/colors";

.tarifs {
      .pocket-shadow{
        -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         background: #ffffff;
             padding-top: 40px !important;
      }


}
.modelhermixtarif{
  .modal-header,.modal-footer{border:0px}
  .modal-content{    background: #edfeff;}

}
.flowpriceheader{
  img{
       width: 65px;
  }
  h2{
       font-size: 20px !important;
    margin-bottom: 25px;
  }
}
.flexrowtarifs{
     display: flex;
    justify-content: center;
    align-items: center;
  .item{

    .pricebox {
    border-radius: 25px;
    -webkit-box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         box-shadow: 0px 20px 59px 0px rgba(0, 0, 0, 0.18);
         background: #ffffff;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
    -o-transition: all 0.5s ease;
    transition: all 0.5s ease;
    padding: 20px;
    label{
      font-weight: bold;
    margin-top: 15px;
    position: relative;
        display: block;
    }
    label:after {
    content: "";
    display: inline-block;
    width: 100px;
    height: 5px;
    background-color: #10aa9b;
    margin-left: 10px;
        position: absolute;
    bottom: -7px;
    left: -8px;
}
p{
      font-size: 13px;
    line-height: 18px;
    margin-top: 20px;
    min-height: 72px;
}

    .pricebox-border2 {
    margin: 18px auto 25px auto;
    background: #E0E4E8;
    width: 56px;
    height: 2px;
}
    ul{
     list-style:none;
     li{
       margin-bottom: 10px;
       font-size: 14px;
     }
    }

    h3{

          text-align: center;
           margin-bottom: 22px;

    }
    h4{
          text-align: center;
          font-size: 25px;
          color: #1dbdc7;
          font-weight: bold;
      sup{
           color: #000;
      }
    }

    .ttcontent{


    }
}

  }
}

@media only screen and (max-width: 1024px) {
    .tarifs {

    }

}