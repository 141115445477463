@import "../../../assets/sass/colors";

.posabscateg{
    position: absolute;
    left: -8px;
    z-index: 99;
    top: 0px;
    width: 280px;
    .accordion-button{
         padding-top: 5px;
          padding-bottom: 5px;
        
    }
    .accordion-item{
          border: 1px solid #e7e7e7;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 0.5rem !important;

    }
}
.categ777{
    width: 277px;
    float: right;
    position: relative;
    height: 42px;
    .accordion-collapse{
       background: #FFF;
      border-bottom: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
   }
}
.categ777flex{
   display:flex;
   align-items:center;    justify-content: flex-end;
   .accordion-item{
     height: 42px;
   }

}
.bgcouverture{
  display:block;
  .headerpageimgweb{
          background-repeat: no-repeat;
    min-height: 360px;
    background-position: center;
    background-size: cover;
    width: 101%;
    margin-bottom: 20px;
    margin-top: 0px;
  }
}
.listing {

.accordion-button:not(.collapsed){
  color:#46c3cc
}

  
   .flitres{
    padding:10px;
     background:#f1f1f1;
     border-radius:5px;
   

     h3{
              border-bottom: 1px solid #eee;
    padding-bottom: 10px;
    padding-top: 9px;
     }
   }

   .orderBy{
     float:right;
     .product__view--select {
    border: 1px solid #e7e7e7;
    padding: 0.6rem 3rem 0.6rem 1.2rem;
    -webkit-appearance: none;
    cursor: pointer;
    border-radius: 0.5rem;
    background: #FFF;
     border-radius: 5px;
    height: 40px;
     }
     .shop__header--select{position:relative;   }
     .select.shop__header--select::before {
    right: 14px;
    }

    .select::before {
    border-bottom: 2px solid #5d5d5d;
    border-right: 2px solid #5d5d5d;
    content: "";
    display: block;
    height: 7px;
    margin-top: -4px;
    pointer-events: none;
    position: absolute;
    right: 18px;
    top: 50%;
    width: 7px;
    -webkit-transform-origin: 66% 66%;
    transform-origin: 66% 66%;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    opacity: .7;
}


   } 
   .form-group--nest {
   position:relative;
   margin-right:5px;
    width: 40%;
    float:right;

     input{
    border-radius: 5px;
    border-color: #dedede;
    border-right: 1px solid #dedede;
    height: 41px;
    width: 100%;
   
     }
     div.ico{
       position: absolute;
    right: 20px;
    top: 9px;
        cursor: pointer;
    z-index: 9999;
     }
   }
   .list_items{
    clear:both;
    overflow:hodden;
    margin-top:30px;
   }

   .accordion-item {
  
    padding-top: 7px;
    padding-bottom: 4px;
    .accordion-button{
    font-size: 16px;
    font-weight: normal;
    font-family: 'Work Sans';
    }
    .accordion-button:not(.collapsed){
     background:#FFF;
     border-bottom:none;
     box-shadow:none;
    }
}
.accordion-button:focus{
    border:none !important;
    box-shadow:none !important;
}

ul.catglist{
    li{padding-top:5px;padding-bottom:5px; list-style:none; cursor:pointer}
    .active{border-bottom:2px solid #ccc}
}

ul.Xgz5B{
display: inline-block;
    list-style: none;
    padding: 0;
    li{
          margin-right: 5px;
          display: inline-block;
          margin-top: 8px;

    ._3EX1c {
    border: 0 !important;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute !important;
    width: 1px !important;
     }
     ._1xuQr {
    height: 20px;
    width: 20px;
    border-radius: 50%;
    display: block;
    position: relative;
    cursor: pointer;
    border: 1px solid #c9c9c9;
    }

    ._1xuQr:after {
    content: "";
    background: transparent;
    position: absolute;
    border-radius: 50%;
    top: -4px;
    right: -4px;
    bottom: -4px;
    left: -4px;
    opacity: 0.5;
   }
    }
}
._3AtSb{
     font-size: 13px;
    color: inherit;
    font-style: italic;
    white-space: nowrap
}
._3AtSr{
       font-size: 13px;
    color: inherit;
    font-style: italic;
    display: block;
    float: right;
}

ul._1A0B3{
        padding: 0;
    margin: 0;
    list-style: none;
    li{
      ._1LXRy {
            position: absolute;
            opacity: 0;
        }
     ._1LXRy+label {
            position: relative;
            padding: 0;
            cursor: pointer;
        }
      ._3dpj1 {
            display: block;
            font-size: 14px;
            line-height: 1.7;
            cursor: pointer;
        }
        ._1LXRy+label::before {
            display: inline-block;
            width: 13px;
            height: 13px;
            vertical-align: text-top;
            content: "";
            background: white;
            border: 1px solid #c9c9c9;
        }
        ._3dpj1 ._1Cv08 {
                opacity: 0.9;
                    padding-left: 10px;
            }

    ._1LXRy:checked+label::after {
    position: absolute;
    top: 5px;
    width: 4px;
    height: 8px;
    content: "";
    border: solid black;
    border-width: 0 1px 1px 0;
    transform: rotate(45deg);
}


    }
}

.ps-block--site-features .ps-block__item{
    display:block;
}
.ps-block__left{text-align:center}
.ps-block--site-features .ps-block__left i {
    font-size: 50px;
    color: #606060;

}
.ps-block--site-features .ps-block__right {
    padding-left: 0px;
    text-align: center;
    margin-top: 20px;
}
}

@media only screen and (max-width: 1024px) {

.ps-product .ps-product__thumbnail{
  min-height: 180px;
   height: 180px !important;
}

.bgcouverture{
  display:none
}
    .categories {
    .ishiden{
      overflow: hidden;
    }

 

      .is-sticky {
    position: fixed;
    top: 0;
    left: 10px;
    width: 100%;
    z-index: 999;
    background-color: #ffffff !important;
    animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
    padding-top: 10px;
    padding-bottom: 4px;
    box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
      -webkit-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
       -moz-box-shadow: 0px 3px 7px -2px rgba(59,59,59,0.75);
}
    }
}
