@import "../../../assets/sass/colors";


 .messages{
 .flx889{
   display: flex;
    justify-content: space-between;
    align-items: center;
    select{
     border: none;
         margin-bottom: 0px !important;

    }
 }
.ffform{
	     margin-bottom: 20px;
    background-color: white;
    padding: 15px;
    border-radius: 8px;
    margin-top: 21px;
    overflow: hidden;

}
 .btn11{
   a{
        display: block;
    font-size: 13px;
    text-align: center;
    width: 180px;
    margin: 0 auto;
    padding: 10px;
      box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
-moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
    border-radius: 25px;
    color:#073460;
    img{ margin-right: 10px;}
   }
 }

  .msgset1{
    ul{
         padding: 0px;
    margin: 0px;
    margin-top: 25px;
    margin-bottom: 25px;

    li.active{

     color:#e99f28;
  
        background: #ffeed3;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    }
      li{
      margin-bottom: 5px;
      font-size: 13px;
         padding-top: 8px;
    padding-bottom: 8px;
    padding-right: 8px;
    font-weight:bold;
    padding-left: 10px;

     list-style: none;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    .img{
       width: 19%;
    }
    .txt{
          width: 70%;
    }
    .nmb{
   width: 10%;
   text-align:right;
    }

       img{
              width: 20px;
       }
       div{

       }
       span{

       }

      }
    }
  }
  .sect2{
      display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
   a{
       display: block;
    padding: 7px 20px;
    color: #FFF;
    background: #d6d6d6;
    border-radius: 5px;
    margin-right:8px;
   }
   a.active{
   background:#e99f28
   }
  }
  .filter-tags{
   display: flex;
    align-items: center;
    label{top:0;}
   input{
         width: 15px;
    height: 15px;
   }
  }
  .contentmessages{
        padding-left: 10px;
    padding-right: 10px;
    background: #f6f6f8;
    overflow: hidden;
    min-height: 518px;
    margin-top: 5px;
    border-radius: 10px;


    ul{
        padding: 0px;
    margin: 0px;
    margin-top: 10px;
    margin-bottom: 25px;
    }
     li{

        list-style: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 7px;
    padding-bottom: 7px;
    border-bottom: 1px solid #cfcfcf;;
    .f2{
         margin-left: 10px;
         margin-right:10px;
           font-size: 12px;
     }
     .f3{
      font-size: 12px;
      margin-right:10px;
     }
     .f4{
       img{
        width: 20px;
       }
       .statut{
           padding: 3px 10px;
    border-radius: 20px;
    font-size: 11px;
    color:#FFF;
        min-width: 72px;

       }
       .st1{
         background:#073460
       }
         .st2{
         background:#1747ee
       }
         .st3{
         background:#646464
       }
         .st4{
         background:#e99f28
       }
         .st5{
         background:#4ec5ca
       }
     }
    }
  }
  .missionscnt{
   .f4{
     margin-right:10px
   }
  }
 }

@media only screen and (max-width: 1024px) {
    
}
