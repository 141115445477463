@import "../../../assets/sass/colors";

.mescommandes {
    min-height: auto  !important;
    display: flex;
    align-items: center;

   .tavblemob{
    display:none
   }
   .tavbleweb{
     display:block
   }
   .orderbyorder{
     background: #46c3cc;
    border-radius: 7px;
    color: #FFF;
    font-size: 12px;
    width: 100%;
    padding: 2px 12px;
   }
    .filtreorder{
           display: flex;
          justify-content: space-between;
         align-items: center;
         margin-bottom:10px;
         .item{

            .colitem{
                      display: flex;
                     justify-content: flex-start;
                         align-items: center;
             .iiin{
                   margin-left: 10px;
                   width: 200px;
                   input{
                   height: 30px;
                   }
             }
               span{
                    display: block;
                        display: block;
    font-size: 11px;
               }
               select{
                    width: 100%;
    text-align: center;
    font-size: 11px;
    border-radius: 7px;
    padding: 2px 8px;
    padding-right: 0px;

               }
            }
         }
    }

    .orderactionmob{
      margin-bottom:10px;
          overflow: hidden;
    }

    .orderactionitem{

           display: block;
    justify-content: space-around;
    align-items: center;
       .actv{
            border-bottom: none;
            background:#a1a1a1;
      }
      a{
            min-width: max-content;
    background: #000;
    color: #FFF;
    font-weight: bold;
    margin-bottom: 20px;
    padding: 5px 10px;
    display: block;
    float: left;
    margin-right:10px;
      }

    }

    .logo{
        height: 50px;
    }
    .badge{
         min-width: 103px;
    }


    .list-contr a {
     line-height: 32px;
    color: #fff;
    float: left;
    width: 100%;
    position: relative;
    border-radius: 10px;
    margin: 0 4px;
}
.list-contr a.view {
    background: #4DB7FE;
}

.list-group-item{
    cursor: pointer;
    }
    .hide-div{
        display:none;
    }
    .active{
    background: #fcb800;
    color: #FFFFFF;
    }
    .nav-style{
        text-align: left;
    }
    .type-con {
        border-radius: 20px;
        border: solid 0.3px $grey2;
        background-color: $grey;
        display: inline-flex;
        padding: 2px;
        .type {
            padding: 7px 25px;
            border-radius: 20px;
            font-size: 12px;
            cursor: pointer;
            transition: all 400ms;
            &.active {
                background-color: $green;
                color: white;
            }
        }
    }
    form {
        background-color: white;
        padding: 30px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
    }
    p{
        color: $grey3;
    }
    a{
        color: $text-color;
        text-decoration: none;
        line-height: 50px;
    }
    .btn{
    }
}

@media only screen and (max-width: 1024px) {

    .mescommandes{

    .orderactionmob{
           display: flex;
        justify-content: flex-start;
        align-items: center;
        overflow: scroll;
    }

    .mb5mob{
     padding:5px;
    }


    .tavblemob{
      display:block !important;
    margin-top:30px;
    margin-bottom:30px;
    .items{
    a{
     line-height: 23px;
    }
      .rowitmtop{
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;
      }
    }
        .process-item{
            padding-left: 5px;
    padding-right: 5px;
            padding-top: 10px;
    padding-bottom: 10px;
          box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
        -webkit-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
        -moz-box-shadow: 0px 3px 8px -1px rgba(0,0,0,0.46);
            border-radius: 10px;
            background: #FFF;
            margin-bottom:20px;
            strong{
                  margin-bottom: 11px;
                  display: block;
            }
            .rowsitm{
                margin-bottom: 10px;
              display: flex;
    justify-content: space-around;
    align-items: center;
    div{
         width: 33.33%;
    }
            }
       }
    }
         .orderactionitem{

           display: flex;
    justify-content: space-around;
    align-items: center;
      a{
             line-height: 20px;
    color: #000;
    font-weight: bold;
    font-size: 9px;
    margin: 0 auto;
    margin-bottom: 20px;
    background:#FFF;
      }

        .actv{
            border-bottom: 2px solid #000;
            background:#FFF;
      }
    }
    }
}
