@import "../../../assets/sass/colors";
.register {
    min-height: 100vh;
    display: flex;
    align-items: center;
    .logo{
        height: 50px;
    }
    .list-group-item{
    cursor: pointer;
    }
    .hide-div{
        display:none;
    }
    .active{
    background: #000;
    color: #FFFFFF;
    }
    .nav-style{
        text-align: left;
    }
    .type-con {
        border-radius: 20px;
        border: solid 0.3px $grey2;
        background-color: $grey;
        display: inline-flex;
        padding: 2px;
        .type {
            padding: 7px 25px;
            border-radius: 20px;
            font-size: 12px;
            cursor: pointer;
            transition: all 400ms;
            &.active {
                background-color: $green;
                color: white;
            }
        }
    }
    form {
        background-color: white;
        padding: 30px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
    }
    p{
        color: $grey3;
    }
    a{
        color: $text-color;
        text-decoration: none;
        line-height: 31px;
    }
    .btn{
    }
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
