@import "../../assets/sass/colors";

.home {
     .clearboth{
      clear: both;
     }

     .section4 .ps_block_flex{
     min-height: 250px;
     }

     .col-img-667{
       img{
         width:100%
       }
     }
     .col-title-home{
         margin-top: 99px;
      h1{

        font-weight: inherit;
      text-decoration: none;
      text-shadow: 0 6px 10px rgba(0,0,0,.1);
      color: #FFF;
    font-size: 57px;
    font-weight: bold;
        sup{
            font-size: 20px;
         }
      }
      p{
           color: #FFF;
          font-size: 18px;
      }

     }
     .col-9028-K{
       li{
        margin-bottom:30px;
       }
     }

     .ttilico889{
      padding-left: 15px;
     }
.section3{overflow:hidden; background:#edfeff}
.section4{background:#FFF}
.section5{background:url('../../../public/images/bgavis.png');    background-repeat: repeat;    padding-bottom: 70px;}
  .ps-block__left {
margin-bottom:20px;
    i{
            font-size: 25px;
    border: 1px solid #1dbdc7;
    color: #1dbdc7;
    border-radius: 50px;
    padding: 8px;
    }
       em{
            font-size: 25px;
    border: 1px solid #1dbdc7;
    color: #1dbdc7;
    border-radius: 50px;
    padding: 8px;
    }
}
.colli990 li{font-weight:bold;}
.mrgtop90{margin-top:50px}
.tith3990{
   font-size:25px;
   margin-top:30px;
}

.clientSayWrapper {
    position: relative;
    padding: 20px 20px 0;
        padding-bottom: 71px;
 
}
.slick-prev, .slick-next{
  top: 32%;
}

.clientSay {
  background: #fff;
  padding: 34px 35px 34px 66px;
  position: relative;
  box-shadow: 0px 0px 12px rgba(0, 0, 0, .05);
  margin-bottom: 33px;
}
.clientSay:after {
  position: absolute;
    bottom: -22px;
    left: 67px;
    content: '';
    width: 0;
    height: 0;
    border-left: 0px solid transparent;
    border-right: 25px solid transparent;
    border-top: 22px solid #fff;
}

.clientSay i.fa {
  position: absolute;
  top: 30px;
  left: 15px;
  font-size: 33px;
  color: #35cdd7;
}

.sayPersone {
  position: relative;
  padding: 10px 0;
}

.sayPersone img {
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 100%;
  max-width: 100%;
  height: auto;
  display: inline-block;
}

.personeInfo {
  padding-left: 86px;
}

.sayPersone p {
  margin-bottom: 5px;
  font-size: 15px;
  line-height: 20px;
}

.sayPersone span {
  display: block;
  font-size: 12px;
  line-height: 20px;
  color: #c4be8d;
  text-transform: uppercase;
}


.simple-article {
    color: #8e8d8d;
    font-size: 14px;
    line-height: 26px;
}

.slick-dots{
  display: flex !important;
  justify-content: center;
}
.ps-banner--market-4{position:relative;position:relative;min-height: 250px;  background: #6feaf2;
    background: linear-gradient(90deg, #43c3cc 0%, #4fc5cd 35%, #6feaf2 100%);   padding-top: 50px;}
    .ps-banner--market-4 .ps-banner__content {
    max-width: 1200px;
    width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-left: 140px;
}
.ps-banner--market-4 h3 {
   font-size: 27px;
    line-height: 42px;
    color: #3d3d3d;
    font-family: "Work Sans", sans-serif;
    font-weight: normal;
}
   .bgheader{
        background:url('../../../public/images/bgheader.png');
            width: 100%;
    height: 135px;
    position: absolute;
    bottom: -63px;
   }










     .cardplatform {
      cursor:pointer
     }

        .cardplatform1{
               min-height: 193px;
    border: 0;
    border-radius: 8px;
    position: relative;
    margin-top: 49px;
      }
     .surcommandetitle {
     display: flex;
    align-items: center;
    position: absolute;
    justify-content: center;
    text-align: center;
    width: 100%;
    height: 100%;
        
        p{
             background: #000000;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.7;
   font-size: 15px;
    width: max-content;
       color: #FFF;
   
    letter-spacing: 1px;
    margin: 0 auto;
        }
     }
    .categformobile{
      display:none;
    }
    .mixedtitle{
     position: absolute;
    width: 100%;
    top: 15%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
    text-align: left;
    color: #FFF;
    font-size: 25px;
    letter-spacing: 3px;
     margin:0px;
     line-height: 20px !important;
      }
    h3{
    margin:0px;
    color: #FFF !important;
    text-align: left;
    font-size: 20px !important;
    line-height: 20px !important;
    }
    div{
          background: #000000;
    padding: 10px;
    border-radius: 10px;
    opacity: 0.5;
        margin-right: 20px;
    margin-left: 20px;
    }
    }
     .categforweb{
      display:block;
    }
    .ps-banner_img{
    position:relative;
    text-align:center;
        min-height: 350px;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
      img{

         margin: 0 auto;
          max-height: 340px;

      }
          
    }

    .storiesflex{
    clear: both;
    margin: 0 auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    overflow-x: auto;
        padding-top: 10px;
    .item{
       margin-right: 16px;
       width: 20%;
    }
    }

.chosecateg .col-md-3{padding-left:0px; padding-right:0px}
.cardplatform{
      width: 100%;
   
        background-repeat: no-repeat;
    background-size: cover;
    text-align:center;
    h2{
     text-transform:uppercase;
    }
    p{
     color:#000
    }

       .fashion{
                background:#FFF; 
                
            img {
                max-width: 50%;
                vertical-align: middle;
                margin-top: 20px;
              }
              h2{
                margin-top: 10px;
              }

    }
       .africreation{
                background:#FFF; 
               
            img {
                max-width: 50%;
                vertical-align: middle;
                margin-top: 20px;
              }
              h2{
                margin-top: 10px;
              }

    }

.vendtout{
                background:#FFF; 
        
            img {
                max-width: 50%;
                vertical-align: middle;
                margin-top: 20px;
              }
              h2{
                margin-top: 10px;
              }

    }

.surcommande{
               background:#FFF;
                
            img {
                max-width: 40%;
                vertical-align: middle;
                margin-top: 20px;
              }
              h2{
                margin-top: 10px;
                color:#FFF;
               

              }
              p{
               color:#FFF;
              }

    }

    .ebay{margin-top: 20px;}
    .cdiscount{margin-top: 39px;}
}
.ps-block--site-features .ps-block__item{display:block}
.ps-block--site-features .ps-block__right{padding-left:0px; h4{margin-bottom:20px;    text-transform: uppercase;}}

.ps-banner--market-4 h4 {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #0098ce;
}

.ps-block--site-features .ps-block__item{border-right:0px}
    .section1 {
        background: linear-gradient(45deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6)), url(../../assets/images/landing.svg);
        min-height: 550px;
        width: 100%;
        background-position: top center;
        background-repeat: no-repeat;
        background-size: cover;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-attachment: fixed;
        h1 {
            color: white;
            text-align: center;
            font-family: "Montserrat-Light", sans-serif;
            span {
                color: $primary;
                font-family: "Montserrat-ExtraBold", sans-serif;
                text-transform: uppercase;
            }
        }
    
        form {
            border: solid 0.3px #a9b6c4;
            background-color: white;
            border-radius: 10px;
            display: flex;
            align-items: center;
            padding: 5px;
            input{
                border: none;
                border-radius: 0;
                background:transparent;
                min-width: 300px;
            }
            .btn{
                font-size: 14px;
            }
        }
    }
    .section2{
        padding: 50px 0;
        background:#FFF;
        h3{ 
          margin-bottom:15px !important;
         }
    }
    .uppercase{
        color: $primary;
        font-family: "Montserrat-ExtraBold", sans-serif;
        text-transform: uppercase;
    }



    .w-32 {
    width: 8rem;
}
.relative {
    position: relative;
}
.h-32 {
    height: 8rem;
}
.rounded-full {
    border-radius: 9999px;
}
.w-full {
    width: 100%;
}
.h-full {
    height: 100%;
}
.rounded-full {
    border-radius: 9999px;
}
.bg-white {
    background-color: #fff;
}

  .conic-gradient:after {
  display: block;
  border-radius: 9999px;
  content: '';
  width: 111%;
  height: 111%;
  position: absolute;
  z-index: -1;
  left: -5%;
  top: -5%;
  background-image: conic-gradient(#c7287e, #c58ad2, #e597bf, #ffcb83, #d04c22);
  animation: spin 5s linear infinite;
}

@keyframes spin { 
  100% { 
    transform: rotate(360deg);
  }
}




    .stories{
      div{
        width: 70px;
    height: 70px;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
     margin: 0 auto;
      }
    }

}

.seemore{
     padding-top: 11px;
    font-size: 12px;
    img{
         width: 32px;
    }
}


@media only screen and (max-width: 1024px) {
    .home {

  .slick-dots{display:none}

  .mixedtitle{
     position: absolute;
    width: 100%;
    top: 15%;
    height: 85%;
    display: flex;
    align-items: center;
    justify-content: center;
    h2{
        text-align: center;
    color: #FFF;
    font-size: 21px;
    letter-spacing: 2px;
    margin: 0px;
    line-height: 22px !important;

      }
    h3{
       margin: 0px;
    color: #FFF !important;
    text-align: center;
    font-size: 16px !important;
    line-height: 11px !important;
    margin-top: 6px;
    }
    }

        .bigcategories{
        padding-bottom: 15px;
        max-width: 100%;
        margin: 0 auto;
        display: flex;
        flex-flow: row nowrap;
        justify-content: space-between;
        align-items: center;
        margin-top: 27px;
    margin-bottom: 48px;
        .item{

            width: 100%;
    height: auto;
        text-align: center;
        label{
             font-weight: bold;
    letter-spacing: 0.5px;
    font-size: 11px;
    margin-top: 12px;
    text-align: center;
    width: 100%;
        }

          a{
              display: flex;
          
                margin: 0 auto;
                align-items: center;
                justify-content: center;
                text-align: center;
              
              
          
            
            
          }
            .active{
                  width: 80px !important;
                 height: 80px !important;
                }
            .fashion{
                
                 img{
               width: 85%;
            }
            }
            .afrocreation{
                
                 img{
               width: 85%;
            }
            }
             .ventout{
                 
                 img{
               width: 85%;
            }
            }
             .surcommande{
             
            }
        }
    }
    
     .categforweb{
      display:none;
    }
     .categformobile{
      display:block;
    }
    .section2 {padding-bottom:0px;}
      .ps-banner--market-4{
       min-height: 184px;
             .ps-banner_img{
               img{

               }
             }
      }
     
      .cardplatform p{
           padding-right: 12px;
      }
      .cardplatform  img{
       float: left;
        margin-left: 28px;
      }
      .cardplatform .fashion,.cardplatform .africreation,.cardplatform .vendtout{
           min-height: 145px;
           h2{
                padding-top: 46px;
           }
      }

      .fashion  img{
       
        width: 100px;
        
      }
      .africreation  img{
       
        width: 100px;
        
      }
      .vendtout  img{
       
        width: 77px;
            margin-top: 9px;
            margin-left: 42px;
        
      }
      .surcommande  img{
       
             width: 80px;
             margin-top: 30px !important;
        
      }

 
      .section2 .col-md-3{margin-top:0px}
      .cardplatform{
          min-height:100px;
          border: 0;

          
         
            

      }

    }
}
