@import "../../../assets/sass/colors";


 .abonnement{
  .itemimg {
    width: 130px;
    height: 130px;
    background-size: cover;
    background-position: center;
    border-radius: 50%;
    margin:0 auto;
}
.flexitems{
      display: flex;
    color: #FFF;
    align-items: stretch;
    margin-top: 29px;
 }
 .accordion-item:last-of-type .accordion-button.collapsed{
  font-size:14px
 }
}

@media only screen and (max-width: 1024px) {
    
}
