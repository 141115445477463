@import "../../../assets/sass/colors";
 .flxc0{
 display: flex;
    justify-content: flex-start;
    align-items: center;

  
 }
 .flxc1{
 display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-right: 14px;

    span{
         width: 10px;
    height: 10px;
    display: block;
    border-radius: 50%;
    margin-right:4px;
    }

  
 }

@media only screen and (max-width: 1024px) {
 
 
}
