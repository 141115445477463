@import "../../assets/sass/colors";
   .ps-block__header_active{
          border: 2px solid #CCC;
    border-radius: 10px;
    padding: 6px;
    }
    
.panier {

    min-height: 82vh;
    padding: 60px 0;
    padding-top:0px;
    .fn7789{
      .ps-btn{
      color:#FFF;
      width:100%;
      text-align:center;
    }
 

    .ps-btn:hover{
      background:#606060
    }
    }
    .form-group--nest{
     width:40%
    }
   
      .ps-table--shopping-cart   tr .actionmobile {
            display:none 
     }
    .ps-table--shopping-cart tr td{
              vertical-align: middle;
    }
    h1 {
        color: white;
        text-align: center;
        font-family: "Montserrat-Light", sans-serif;
        span {
            color: $primary;
            font-family: "Montserrat-ExtraBold", sans-serif;
            text-transform: uppercase;
        }
    }
    .button-appliquer{
        padding: 7px 14px !important
     }
     .hide-div{
         display:none;
     }
     .vide-phrase{
        color: #ff914c;
     }
     .prix-barre{
        text-decoration: line-through;
     }
     .card {
        background-color: white;
        padding: 10px 20px 11px;
        border-radius: 5px;
        width: 100%;
        border: 1px solid #ff914c;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
      }
      .pay-button{
        padding: 0.7rem 2rem;
        width: 100%;
        margin: 1rem 0;
        color: #FFF;
        font-weight: bold;
        font-size: medium;
        background-color: #04becb;
        border: 0;
        border-radius: 5px;
        box-shadow: 0px 4px 5.5px 0px rgba(0, 0, 0, 0.07);
        transition: box-shadow 500ms;
        cursor: pointer;
      }
      .pay-button:disabled{
        background-color: #afafaf;
        box-shadow: none;
        cursor: default;
      }
      .pay-button:disabled:hover{
        box-shadow: none;
      }
      .pay-button:hover{
        box-shadow: 2px 5px 15px 2px rgba(0, 0, 0, 0.2);
      }
    form {
        border: solid 0.3px #ff914c;
        background-color: white;
        border-radius: 10px;
        display: flex;
        align-items: center;
        padding: 5px;
        input {
            border: none;
            border-radius: 0;
            background: transparent;
            min-width: 300px;
        }
        .btn {
            font-size: 14px;
        }
        .table-info-panier {
            padding: 30px;
            margin-top: 30px;
        }
       
    }
}

@media only screen and (max-width: 1024px) {
    .panier {
        .ps-table--shopping-cart tr td[data-label] {
          display: flex;
          justify-content: space-between;
          align-items: center;
          border: none;
          text-transform: capitalize;
          border-top: 1px solid #ddd;
          min-width: 100px;
          position: relative;
          }

          .form-group--nest{
     width:100%
    }

       .ps-table--shopping-cart   tr {
        display: block;
        margin-bottom: 10px;
        border: 1px solid #ddd;
     }
     .ps-table--shopping-cart   tr .form-group--number {
         margin:0 auto
     }
  .ps-table--shopping-cart   tr span {
             width: 100%;
             text-align:center
     }

     .ps-table--shopping-cart   tr .actions {
            display:none !important
     }

      .ps-table--shopping-cart   tr .actionmobile {
                  display: block !important;
    position: absolute;
    right: 4px;
    background: #d98181;
    top: 4px;
    color: #FFF;
    padding: 0px 4px;
    border-radius: 20px;
     }


    }
}
