@import "../../assets/sass/colors";
.ps-vendor-banner89 {
   
    padding: 70px 0;
}
.section387 {
    margin-bottom: 30px;
    overflow: hidden;
    background: #f5f5f5;
    padding-top: 50px;
    padding-bottom: 50px;
    img{
      width: 70%;
    }
    .cardplatform{
    text-align:center
    }

.ps-block--site-features .ps-block__item{display:block}
.ps-block--site-features .ps-block__right{padding-left:0px; h4{margin-bottom:20px;    text-transform: uppercase;}}
.ps-block__left {
margin-bottom:20px;
    i{
        font-size: 65px;
    }
}
.ps-block--site-features .ps-block__item{border-right:0px}
.ps-banner--market-4 h4 {
    margin-bottom: 20px;
    font-size: 16px;
    line-height: 20px;
    font-weight: 400;
    color: #0098ce;
}

}
.commentcamarche {
 
    display: flex;
    align-items: center;


    .titleunique{
        background-color: #fb6c13;
        color: #ffffff;
        font-weight: bold;
        height: 32px;
        padding: 5px;
    }



    a{
        color: $text-color;
        text-decoration: none;
    }
  
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
