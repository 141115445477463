@import "../../../assets/sass/colors";

.dashboard {
  .item7790{
  .bg667{
    background:#004aad
  }

   .bg669{
    background:#ff1616
  }

    .bg668{
    background:#008037
  }
  .item887{
    width:100% !important;
  }
    a{
    padding-top: 20px;
    padding-bottom: 20px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
      label{
          margin-left: 20px;
         display: inline-block !important;
         background: #001127;
    padding: 5px 14px;
    border-radius: 5px;
    font-size: 15px !important;
    font-weight: bold;
    color: #cadbf2 !important;
      }
      img{
        margin-right: 10px;
        height:60px !important
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
    .dashboard {
      
    }
}
