@import "../../assets/sass/colors";
.tailactivesent{
  background:#bebebe  !important
}
    .Uy778{
          width: 15px;
    height: 15px;
 
    margin-left: 10px;
    border-radius: 20px;
    }
.ventout {
    .ventouttitle{display:block}


   .addrs11{
         display: flex;
    align-items: center;
    background: #d2f3ff;
    border-radius: 22px;
    padding: 10px;
    width: max-content;
    img{
      width: 18px;
    margin-right: 18px;
    margin-left: 10px;
    }
   }
   .Nonauthentique{
    background: #f9edc8;
    color: #b6912e;
    width: max-content;
    border-radius: 20px;
    padding: 5px 18px;
   }

     .authentique{
     background: #c0e8cb;
    color: #546a5a;
    width: max-content;
    border-radius: 20px;
    padding: 5px 18px;
   }

   
   .flx124{
    display: flex;
    align-items: center;
    justify-content: space-between;
    a.f1{

    color: #FFF;
    padding: 5px 10px;
    font-size: 13px;
    min-width: 49%;
    text-align: center;

    }
    a.f2{
          color: #000;
    padding: 5px 10px;
    background: #FFF;
    border: 1px solid #000;
    font-size: 13px;
    min-width: 49%;
    text-align: center;
    }
    a.f2:hover{
        background: #FFF !important;
    }
   }

}

.modalpublish{
     .modal-content{
           border-top-left-radius: 15px;
    border-top-right-radius: 15px;
     }
      .mop1{ 

            color: #000;
    font-weight: bold;
    font-size: 15px;
    font-family: 'Montserrat-Medium';

       }
       .mop2{
         color: #000;
       }
       .marg5{
         margin-top:10px;
         margin-bottom:10px;
       }
       .marg6{
        margin-bottom:13px;
       }
       label{

       }
       input.form-control,select,.inp{
            background: #eee;
           height: 39px;
            border-radius: 10px;
            font-size: 13px;
            -webkit-appearance: auto;
            border:1px solid #ced4da;
       }
       .inp1{
        display: flex;
    align-items: center;
    padding-left: 10px;
    
       }

       .inp2{
       padding-left: 9px;
         .form-check{
               
               margin-bottom:10px;
               font-size: 18px;
    border-bottom: 1px solid #eee;
    padding-bottom: 10px;
               label{
                    font-size: 14px;
                   font-weight: bold;
               }
         }
       }

    .photosblock{
        display: flex;
        align-items: center;
        justify-content: space-between;
     .item{

      background: #eee;
    border-radius: 9px;
    padding: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

     img{
      width:52px;
      height:50px;

     }

     }
    }
    .btn88900{    width: 100%;
    color: #FFF;
    text-align: center;
    padding: 10px;}
    .psr{font-size: 12px;}


       .flxmdl{
         background: #c2d8ff;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding: 10px 11px;
     img{
             width: 34px;
            margin-right: 20px;
     }
     p{
    margin-bottom: 0px;
    font-size: 13px;
    color:#000;
     }

       }

}

    .modblockgr{
  
   margin-top: 9px;
    margin-bottom: 20px;
    overflow: hidden;
     .item{
            background: #eee;
    width: 23%;
    border: 1px solid #e7e2e2;
    text-align: center;
    border-radius: 20px;
    float: left;
    margin-bottom: 10px;
    margin-right: 5px;
     }
    }
.it889{
     width: 100% !important;
    padding: 10px !important;
    display: flex !important;
    align-items: center !important;
    color: #000 !important;
    background: #e2eff1 !important;
    padding-bottom: 0px !important;
}
@media only screen and (max-width: 1024px) {
.ventout{
.ventouttitle{display:none}
.section-title {
     margin-top:20px
}
.border-left-right-btm::before{
       display:none
 }
}

}
