@import "../../assets/sass/colors";
.product-item {
    width: 100%;
    background: #FFFFFF;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.01);
    border-radius: 10px;
    overflow: hidden;
    transition:500ms ;
    display: flex;
    min-height: 170px;
    border: 2px solid white;
    .image{
        width: 30%;
        background-color: $grey2;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    border-radius: 10px;

    }
    .info{
        width: 70%;
        padding: 8px 15px;
        position: relative;
        justify-content: space-between;
        display: flex;
        flex-direction: column;
        h5{
            display: flex;
            align-items:center;
            justify-content: space-between;
            font-size: 15px;
            margin-bottom: 15px;
            span{
                background-color: $primary;
                border-radius: 10px;
                font-size: 15px;
                color: white;
                padding: 3px 10px ;
                margin-left: auto;
                font-family: 'Montserrat-Medium',sans-serif;
            }
        }
        .address{
            margin-bottom: 10px;
        }
        .btn{
            padding: 4px 20px;
        }
    }

}
.ps-product{
    .psale {
    color: #f30 !important;
}
    .ps-product__actions{width:100%;opacity: 0.9;
    text-align: center;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    .ps-product__title{
    color:#000;
    }
 
    li {
   
    width: 100%;
    a{
    width: auto;
    color: #000;
    height: auto;
    }
    a:hover{
      background:none;
      color:#000;
    }

}
}
.add-to-cart{
    background: #000;
    width: 100%;
    display: block;
    border-radius: 5px;
    color: #FFF;
    text-align: center;
    padding: 5px;
}
}

@media only screen and (max-width: 1024px) {
    .slick-slider {
         .ps-product .ps-product__thumbnail img {
            width: 70%;
            margin: 0 auto;
        }
        .ps-product .ps-product__container{
         width:70%;
          margin: 0 auto;
        }
        .ps-product .add-to-cart{
            width: 70%;
            margin: 0 auto;
        }
    }

       .list_items {
       .ps-product{
          padding:3px;
          .ps-product__price{
           del{display:none}
           text-align:center
          }
          .ps-product__title{
                text-align: center;
                font-size: 13px;
                color: #000;
          }
       }
    }
}
