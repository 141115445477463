@import "../../../assets/sass/colors";

.detailcmd {
 .detcmdweb{
   display:block
 }

 .flswebYYU8{
   float: left;
    margin-right: 22px;
    td{
      text-align:left
    }
 }

  .detcmdmobile{
   display:none
 }

 .cardlivraison label{
     margin-right: 3px;
 }

         .detcmdmobile{
         overflow: hidden;
         display:block !important;
         padding-left:30px;
         .item{
          h6{
             font-size: 20px;
            color: #2866ba;
            font-weight: bold;
            text-align: left;
          }
          .itemrow{
          margin-bottom:20px;
            display: flex;
             justify-content: flex-start;
             align-items: flex-start;
            .img{
                 margin-right: 20px;
             img{
              width: 120px;
             }
                 
            }
            .data{
               font-size: 15px;
               span{
                padding-right:15px;
               }
               table{
                tr{
                  td{
                       padding-bottom: 6px;
                  }
                }
               }
               strong{
                     text-align: left;
                      float: left;
                      clear: both;
                      margin-top: 10px;
                      font-size: 18px;
               }

               ._1xuQr {
                  height: 20px;
                  width: 20px;
                  border-radius: 50%;
                  display: block;
                  position: relative;
                  cursor: pointer;
                  border: 1px solid #c9c9c9;
              }
            }
            div{

            }
          }
         }
       }

 .btnrmv{
  padding: 10px;
    border-radius: 10px;
    color: #FFF;
    font-weight: bold;
    float: right;
    line-height: 8px;
    margin-bottom: 11px;
 }
 .flexdiv{
 display: flex;
    justify-content: space-between;
    align-items: center;
 }
 .bty7{     padding-bottom: 10px;
    border-bottom: 1px solid #e5dddd;}

 .suivicmd{
   .item{
   margin-bottom:20px;
   .ico{
     font-size: 43px;
   }
     label{
      font-weight:bold;

       img{
             width: 120px;
                border-radius: 50%;
                display: block;
                margin: 0 auto;
       }
     }
   }
 }
}

@media only screen and (max-width: 1024px) {
    .detailcmd {
          .detcmdweb{
             display:none !important
           }

      .mb-5mob{padding-left:0px;padding-right:0px;}
       .flswebYYU8{
   float: none;
    margin-right: 0px;
 }



    }
}
