.meeting-information {
  background-color: var(--white);
  border-radius: 8px;
  font-size: 12px;
  border: 1px solid var(--grey);
  padding: 12px;
  cursor: pointer;
  position: absolute;
  top: 4.5rem;
  left: 0.5rem;
  max-width: 400px;
  z-index: 2;
}

.meeting-information h1 {
  color: var(--dark-blue);
}
