@import "../../../assets/sass/colors";

.dashboard {
.col-md-4{
         margin-bottom: 26px;
}

.pos88909{
      position: absolute;
    top: -27px;
    right: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    vertical-align: top;
    width: 20px;
    height: 20px;
    color: #ffffff;
    background-color: #f47777;
    border-radius: 25%;
}

.flxdash1{
h5{
     width: max-content;
    margin: 0 auto;
    background: #545454;
    color: #FFF;
    padding: 7px;
    font-size: 13px;
    margin-top: 18px;
    border-radius: 7px;

}
h6{ 
font-size: 16px;
    margin-top: 10px;
    margin-bottom: 30px;
}
.item{
           display: flex;
           margin-bottom:15px;
    align-items: center;
    justify-content: space-between;
    div{ 
    text-align:center;
        width: 50%;
    }
       .coldash{
         img{
                 height: 70px;
         }
         label{
          display: block;
              color: #a3a3a3;
    font-size: 13px;
         }
       } 
}
  
}
  .gradient-bg{
    background-color: #6DB6FD;
    background: -webkit-gradient(linear, 0% 0%, 0% 100%, from(#6DB6FD), to(#6FCFFD));
    background: -webkit-linear-gradient(top, #6DB6FD, #6FCFFD);
    background: -moz-linear-gradient(top, #6DB6FD, #6FCFFD);
    background: -ms-linear-gradient(top, #6DB6FD, #6FCFFD);
    background: -o-linear-gradient(top, #6DB6FD, #6FCFFD);
}

  .gradient-bg1{
    background-color: #5da46d;
    
}
  .gradient-bg2{
    background-color: #ff6271;
  
}
  .gradient-bg3{
    background-color: #6DB6FD;
 
}
  .gradient-bg4{
    background-color: #dab442;
    
}
  .gradient-bg5{
    background-color: #32a2b4;
    
}
  .gradient-bg6{
    background-color: #80adb2;
    
}
.inline-facts-wrap {
    display: block;
    padding: 20px 30px 30px;
    position: relative;
    border-radius: 4px;
    overflow: hidden;
}
.inline-facts-wrap i {
    position: absolute;
    right: 30px;
    top: 10px;
    font-size: 44px;
    color: #fff;
    opacity: 0.4;
}
.inline-facts-wrap .num {
    float: left;
    font-size: 36px;
    color: #fff;
    font-weight: 800;
}
.inline-facts-wrap h6 {
    width: 100%;
    float: left;
    font-size: 14px;
    text-align: left;
    color: #fff;
    font-weight: 400;
    margin-top: 6px;
}
}

@media only screen and (max-width: 1024px) {
    .dashboard {
      
    }
}
