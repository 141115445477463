.hair-check {
  background: white;
  border: 1px solid grey;
  padding: 1rem;
  border-radius: 4px;
  max-width: 600px;
  display: flex;
  flex-direction: column;
  margin: auto;
  justify-content: center;
}

.hair-check video {
  max-width: 100%;
}

.hair-check label {
  display: block;
  color: var(--dark-grey);
  font-size: 12px;
  margin: 1em 0 0.5em 0;
  line-height: 14px;
}

.hair-check input,
.hair-check select {
   border: 1px solid #ccc;
  padding: 0.5em;
  border-radius: 4px;
  color: var(--dark-blue);
  width: 100%;
}

.hair-check h1 {
  font-size: 1em;
  text-align: center;
  margin-bottom: 1em;
  color: var(--dark-blue);
}

.hair-check button {
  margin-top: 2em;
      background: #04bfcc;
    color: #FFF;
}

.hair-check button + button {
  margin-top: 0;
}

.cancel-call {
  background: var(--white);
}
