@import "../../assets/sass/colors";

.methodes {
  .paraleftpp{
       padding-left: 27px;
       text-align: justify;
  }

}
 


@media only screen and (max-width: 900px) {
    .methodes {

            .paraleftpp{
			       padding-left: 10px;
			       text-align: justify;
          }
    }

}