@import "../../assets/sass/colors";
.comptecree {
     
}

@media only screen and (max-width: 1024px) {
    .comptecree {
        form {
            padding: 15px 20px;
        }
    }
}
