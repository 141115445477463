@import "../../../assets/sass/colors";

 .livreur {
         .logout_btn {
   
    position: relative;
    padding: 10px 30px;
    border: none;
   
    color: #fff;
    border-radius: 4px;
    -webkit-appearance: none;
    outline: none;
    cursor: pointer;
    font-size: 12px;
    font-weight: 500;
    background: #495057;
}

      .lab778{
   
    border-radius: 4px;
    color: #FFF !important;
    font-weight: bold;
    font-size: 11px !important;
    width: max-content;
    margin: 0 auto;
    padding: 2px 10px;
}
      .lab778red{
    background: #ff1616;
    
}
   .lab778blue{
    background: #004aad;
    
}
   .lab778black{
    background: #000000;
    
}
      
}