@import "../../../assets/sass/colors";

.dossierpatient {
     .flexitems{
        display:flex;

     }
     .accordion-item:first-of-type .accordion-button{
           font-size: 14px;
    color: #000;
    font-weight: normal;
    font-family: "Work Sans", sans-serif;
     }
     .accordion-button:not(.collapsed) {
   
    background-color: #f1feff;
   
    }
}

@media only screen and (max-width: 1024px) {

    
}
