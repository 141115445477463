@import "../../../assets/sass/colors";


.profile{
    
    .ourspec1{
    div{

    z-index:20;
    padding:6px 10px;
    color:#000;
    z-index:20;
    font-size:11px;
    border-radius:20px;
    box-shadow: 0px 0px 0px 5px rgba(255,255,255,0.2);
    font-weight:bold

    }
        .t1{
      background:#6dabe3
    }
     .t2{
      background:#cba741
    }
   .t3{
      background:#ffc107
    }
    .t4{
      background:#c86c3b
    }
     .t5{
      background:#cec8b8
    }
    } 
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
