@import "../../assets/sass/colors";
.apropos {
    min-height: 100vh;
    display: flex;
    align-items: center;


    .titleunique{
        background-color: #fb6c13;
        color: #ffffff;
        font-weight: bold;
        height: 32px;
        padding: 5px;
    }



    a{
        color: $text-color;
        text-decoration: none;
    }
  
}

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
