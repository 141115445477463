@import "../../assets/sass/colors";
    .bg778635{
     width: 100%;
    background: #FFF;
        padding-top: 67px;
    margin-top: 14px;
        padding-bottom: 38px;
    }
.profile {
    min-height: auto  !important;
    display: flex;
    align-items: center;
    .logo{
        height: 50px;
    }
    input.form-control{
        height: 39px;
    }
    .flx889{
     align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    }
    .progress-bar{
          background-color: #46c3cc;
    }


    .ps-btn-dark{
    background:#000;
    color:#FFF
}
   
     .active{
    background: #fcb800;
    color: #FFFFFF;
    }
   .picture-profile{
        height: 50px;
         align-items: center;
    }
    .type-con {
        border-radius: 20px;
        border: solid 0.3px $grey2;
        background-color: $grey;
        display: inline-flex;
        padding: 2px;
        .type {
            padding: 7px 25px;
            border-radius: 20px;
            font-size: 12px;
            cursor: pointer;
            transition: all 400ms;
            &.active {
                background-color: $green;
                color: white;
            }
        }
    }
    form {
        background-color: white;
        padding: 30px 60px;
        border-radius: 8px;
        box-shadow: 3px 13px 27px -19px rgba(0, 0, 0, 0.11);
    }
    p{
        color: $grey3;
    }
    a{
        color: $text-color;
        text-decoration: none;
    }
    .btn{
    }
}

 .agenda{
.fc-customButtons-button{display:none !important}
   .fc-toolbar-chunk{
     div{
       display:flex;
     align-items: center;
     }
     .fc-toolbar-title{
       margin-right:10px;
       color: #46c3cc;
    font-size: 21px;
     }
     button.fc-prev-button ,  button.fc-next-button {
       background: #f6f6f8 !important;
    font-size: 16px;
    color: #073460;
    border: none;
     }
       button:hover{
       background: #f6f6f8;
       color: #073460;
   
     }
   }

   .fc-button-group{
   background: #d5ecee;
    padding: 6px;
    border-radius: 10px;
    button{

     background: none;
     color: #979393;
    border: 0;
    text-transform: capitalize;
    }
      button:hover{

     background: none;
     color: #979393;
    border: 0;
    text-transform: capitalize;
    }
    .fc-button-active{
         background: #FFF !important;
    border-radius: 10px !important;
    color: #4ec5ca !important;
    }


    
   }
     .fc-scrollgrid-sync-table a{
     color:#073460
    }
  .fc-col-header{



      thead{

        tr{
          background: #eeeeee;
          th{
                border: none !important;
          }
        }
      }
      .fc-col-header-cell-cushion{
       color:#073460;
       font-weight: normal;
      }
      .fc-daygrid-day-number{
       color:#073460;
       font-weight: bold;
      }
    }

    .fc-timeGridWeek-view{
    .fc-timegrid-divider{
     display:none;
    }

      thead{
      th{
      height: 40px;
    align-items: center;
    justify-content: center;
        vertical-align: middle;
      }

        
          .fc-scroller{
                overflow: hidden !important;
                background: #eeeeee;
          }

      }
      .fc-scrollgrid-sync-table{display:none}
    }

    .fc-theme-standard td, .fc-theme-standard th {
    border:none !important;
    border-top: 1px solid #ddd !important;

   
   }
     .fc-theme-standard td {
      background:#FFF;
     
      .fc-daygrid-day-top{
        a{
           text-align:center;
           margin: 0 auto;
        }
      }

   
   }
   .fc-dayGridMonth-view table.fc-scrollgrid-liquid{
      border-right: 1px solid #ddd !important;
      border-bottom: 1px solid #ddd !important;
      border-radius: 10px;
   }
   #contentevnetdatesco{
    position: absolute;
    background: #f6f6f8;
    left: 35%;
    height: 20px;
    top: 12px;
    overflow: hidden;
    .datepickercnt{
     background: #f6f6f8;
    }

   }
 }

@media only screen and (max-width: 1024px) {
    .register {
        form {
            padding: 15px 20px;
        }
    }
}
